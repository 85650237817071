.filter-category {
  background: $color-gray-100;
  padding: 15px $grid-gutter-width - 10px;
  padding-bottom: 5px;
  margin-bottom: 2rem;

  &__title {
    color: $color-text-body;
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
  }

  &__list-item {
    padding-left: 5px;

    @media #{$media-md} {
      display: block !important;
    }

    & .custom-radio {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      padding-top: 10px;

      input[type="radio"] {
        position: relative;
        margin-top: 6px;

        &::before {
          content: '';
          width: 22px;
          height: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: $color-white;
          border-radius: 100%;
          border: 2px solid $color-border-box-price-plans;
        }
      }

      input[type="radio"]:checked {
        &::after {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: $color-border-box-price-plans;
          border-radius: 100%;
        }
      }
    }

    & label {
      margin-left: 15px;
      display: block;
      color: $color-primary;
      text-decoration: none;
      padding-bottom: 5px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  @media #{$media-screen-mobile} {
    box-shadow: 0px 3px 3px $gray-500;

    &__title {
      &:after {
        content: $icon-minus;
        font-family: 'icomoon';
        position: absolute;
        top: 2px;
        right: 0;
        color: $color-gray-600;
        font-size: 20px;
      }

      &.collapsed:after {
        content: $icon-plus;
        font-family: 'icomoon';
        position: absolute;
        top: 2px;
        right: 0;
        color: $color-gray-600;
      }

    }
  }

  @media #{$media-screen-mobile-normal} {
    margin-right: - calc($grid-gutter-width / 2);
    margin-left: - calc($grid-gutter-width / 2);
  }
}
