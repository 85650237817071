.review-slider {
  position: relative;
  bottom: -130px;
  margin-top: -130px;

  &__wrap {
    overflow-x: clip;
  }

  &__section-title {
    margin-bottom: 24px;
    text-align: center;
    color: $color-primary;
    font-size: 39px;
  }

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    &--number {
      font-size: map-get($font-size, "size-7");
      margin-left: 16px;
      margin-right: 5px;
    }

    &--star-img {
      width: 100%;
      height: 24px;
      margin-right: 4px;
    }

    &--logo {
      width: auto;
      height: 24px;
    }
  }

  &__card {
    position: relative;
    margin-bottom: 16px;
  }

  &__content {
    margin: 0 16px;
    padding: 24px;
    background: $color-white;
    position: relative;
    height: auto;
    min-height: 250px;
    box-shadow: 0 0 0 0 rgba(22, 114, 96, 0.10), 0 22px 48px 0 rgba(22, 114, 96, 0.10), 0 87px 87px 0 rgba(22, 114, 96, 0.09), 0 196px 117px 0 rgba(22, 114, 96, 0.05), 0 348px 139px 0 rgba(22, 114, 96, 0.01), 0 543px 152px 0 rgba(22, 114, 96, 0.00);

    &--date, &--user {
      font-size: map-get($font-size, "size-7");
      font-weight: 600;
    }

    &--head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--star-img {
      width: 100%;
      height: 20px;
      margin-right: 4px;
    }

    &--date {
      text-align: right;
    }

    &--text {
      font-size: map-get($font-size, "size-7");
      color: $color-tertiary;
      margin: 16px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--footer {
      position: absolute;
      bottom: 24px;
    }
  }

  .slick-slider .slick-list {
    overflow: visible;
  }

  @media #{$media-screen-mobile} {
    &__section-title {
      font-size: 32px;
    }

    &__rating {
      margin-bottom: 24px;
    }
  }

  .mover {
    animation: moveSlideshow 14s linear infinite;
    animation-play-state: running;
  }

  .tech-slideshow:hover .mover {
    animation-play-state: paused;
  }

  @media #{$media-not-desktop} {
    .mover {
      animation: unset;
    }

    .tech-slideshow:hover .mover {
      animation-play-state: unset;
    }
  }

  @keyframes moveSlideshow {
    100% {
      transform: translateX(-66.6666%);
    }
  }
}

