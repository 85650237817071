.promotions-card__slide {
  background-color: $color-primary;
  margin-left: -15px;
  margin-right: 15px;
  padding: 65px 24px 22px 24px;
  text-align: center;
  color: $color-white;
  width: 375px;
  position: relative;
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in;

  &.card-open {
    display: block;
    opacity: 1;
    transition: all 0.3s ease-in;
    filter: blur(0);
  }

  &--top {
    margin-bottom: 48px;
  }

  &--usage {
    span {
      font-size: 16px;
      font-weight: 700;
    }

    img {
      filter: brightness(10);
      vertical-align: sub;
      cursor: pointer;
      display: inline;

      &:hover {
        filter: none;

        & + .promotions-card__slide--tooltip {
          display: block;
        }
      }
    }
  }

  &--input {
    text-align: center;

    span {
      font-weight: 700;
    }

    input {
      background-color: $color-primary;
      border: 0;
      border-bottom: 1px solid white;
      border-radius: 0;
      width: 35%;
      color: $color-white;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      padding: 0;
    }
  }

  &--mid {

  }

  &--saving {
    margin-bottom: 8px;

    &-text {
      font-size: 16px;
      font-weight: 400;
    }

    &-number {
      font-size: 48px;
      font-weight: 700;
      line-height: 53px;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  &--compare {
    margin-bottom: 20px;

    &-text {
      font-size: 14px;
      font-weight: 400;

      span {
        font-weight: 700;
      }

      img {
        filter: brightness(10);
        vertical-align: sub;
        cursor: pointer;
        display: inline;

        &:hover {
          filter: none;

          & + .promotions-card__slide--tooltip {
            display: block;
          }
        }
      }
    }
  }

  &--rectangle {
    &-wrap {
      width: 100%;
      text-align: -webkit-center;
    }

    display: flex;
    margin-bottom: 44px;
    background-color: rgba(148, 219, 209, .5);
    transform: skew(-20deg);
    padding: 8px 20px 8px 8px;
    width: fit-content;

    img {
      padding: 8px 12px;
      transform: skew(20deg);
    }

    &-text {
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      transform: skew(20deg);

      span {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  &--action {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    &-view, &-download{
      display: flex;
    }

    &-view a, &-download a {
      font-size: 14px;
      font-weight: 700;
      color: $color-white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  &--close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &--tooltip {
    position: absolute;
    right: -20px;
    display: none;
    padding: 8px;
    border-radius: 4px;
    background: rgba(0, 57, 73, 0.64);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    width: 60%;
    z-index: 1;

    &-text {
      font-size: 12px !important;
      font-style: italic;
      font-weight: 400 !important;
      line-height: 18px;
    }

    a {
      color: $color-white;
    }
  }

  @media #{$media-screen-tablet} {
    width: 355px;
  }

  @media #{$media-screen-mobile} {
    width: 345px;
    margin-left: 15px;

    &.card-open.custom {
      margin: auto;
      width: 350px;
    }

    &--action {
      flex-direction: column;

      &-view, &-download {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;

        img {
          margin-right: 8px;
        }
      }
    }
  }
}
