#fullscreen-masthead {
  position: relative;
  width: 100%;
  height: 100%;

  .blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 2;
    pointer-events: none;
    display: none;
  }
}

.hero-image {
  &__container {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 100vh;
    position: relative;

    @media (max-height: 684px) {
      min-height: 684px;
    }

    &--mobile {
      .hero-image__fluid {
        width: 100%;
        height: auto;
      }
    }
  }

  &__hr-line {
    font-size: 12px;
    font-weight: 600;
    color: $color-white;
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 104px;
    margin: 0 auto;
    z-index: 2;

    &:after {
      content : "";
      display: block;
      padding-top: 8px;
      height  : 1px;
      width   : 75%;
      margin: 0 auto;
      border-bottom:1px solid $color-white;
    }
  }

  &__ver-line {
    border-left: 1px solid $color-white;
    height: 104px;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media #{$media-xl} {
      position: absolute;
      width: 100%;
      top: 20%; bottom: 0;
      left: 0; right: 0;
    }
  }

  &__info {
    @include column-gutter();
    z-index: 1;
    position: relative;
    flex: 60% 0 0;
    padding-top: $component-bottom;
    padding-bottom: $component-vertical-padding;

    .hero-image__title {
      color: $color-tertiary;
      font-size: 56px;
      font-weight: 700;
      line-height: 67px;
    }

    .hero-image__desc {
      color: $color-tertiary;
      font-size: map-get($font-size, "size-5");
      font-weight: 600;
      line-height: 26px;
      padding: 50px 20% 0;
    }
  }

  &__inner-desc{
    position: relative;
    text-align: center;
    justify-content:center;
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
  }

  &__desc {
    h1 {
      font-size: 88px;
      font-weight: 700;
      margin: 16px 0;
      color: $color-white;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      color: $color-white;
    }
  }

  @media (min-width: #{667px}) and (max-width: #{1199px}) {
    &__inner-desc {
      height: 80%;
    }
  }

  @media #{$media-screen-mobile} {
    &__desc {
      margin: 0 30px;

      h1 {
        font-size: 48px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}

.hero-video {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
  height: 100vh;

  @media (max-height: 684px) {
    min-height: 684px;
  }

  &__container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__fluid {
    display: block!important;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

    @media #{$media-screen-mobile} {
      visibility: hidden;
    }
  }

  &__hr-line {
    font-size: 12px;
    font-weight: 600;
    color: $color-white;
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 104px;
    margin: 0 auto;
    z-index: 2;

    &:after {
      content : "";
      display: block;
      padding-top: 8px;
      height  : 1px;
      width   : 75%;
      margin: 0 auto;
      border-bottom:1px solid $color-white;
    }
  }

  &__ver-line {
    border-left: 1px solid $color-white;
    height: 104px;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media #{$media-xl} {
      position: absolute;
      width: 100%;
      top: 20%; bottom: 0;
      left: 0; right: 0;
    }
  }

  &__info {
    @include column-gutter();
    z-index: 1;
    position: relative;
    flex: 60% 0 0;
    padding-top: $component-bottom;
    padding-bottom: $component-vertical-padding;

    .hero-image__title {
      color: $color-tertiary;
      font-size: 56px;
      font-weight: 700;
      line-height: 67px;
    }

    .hero-image__desc {
      color: $color-tertiary;
      font-size: map-get($font-size, "size-5");
      font-weight: 600;
      line-height: 26px;
      padding: 50px 20% 0;
    }
  }

  &__inner-desc{
    position: relative;
    text-align: center;
    justify-content:center;
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
  }

  &__desc {
    h1 {
      font-size: 88px;
      font-weight: 700;
      color: $color-white;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      color: $color-white;
    }
  }

  @media #{$media-screen-mobile} {
    &__desc {
      margin: 0 30px;

      h1 {
        font-size: 48px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}

.mini-nav {
  @include make-container-fluid();
  bottom: 32px;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $color-secondary;
    transform: skewX(-25deg);
  }

  &__wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 8;

    &.sticky {
      position: fixed;
      top: 0;

      .navbar-brand {
        &.for-desktop {
          display: block;
        }
      }

      .mini-nav {
        background-color: transparent;
        bottom: 0;
      }

      .back-to-top__wrapper {
        display: block;
      }

      .scroll-indicator-wrapper {
        display: block;
      }
    }

    .back-to-top__wrapper {
      display: none;
      margin: auto 0;
      padding: 0 40px;
      cursor: pointer;
      z-index: 1;
    }

    .scroll-indicator-wrapper {
      display: none;
      position: absolute;
      left: -11px;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: $color-tertiary;
      z-index: 2;
      transform: skewX(-25deg);
    }

    .scroll-indicator {
      height: 100%;
      width: 0;
      background-color: $color-primary;
    }
  }

  a.navbar-brand {
    color: $color-white;
    pointer-events: none;
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    padding: 12px 32px;
    z-index: 1;
  }

  .navbar-brand {
    &.for-desktop {
      display: none;
    }

    &.for-mobile {
      display: none;
    }
  }

  .navbar-toggler {
    z-index: 1;
  }

  &__box {
    display: none;
    width: max-content;
    background-color: $color-secondary;
    position: absolute;
    top: 48px;
    right: 0;
    margin-top: 8px;

    a.nav-item.nav-link {
      display: block !important;
      color: $color-white;
      text-decoration: none;
      padding: 16px 32px !important;
      text-align: left !important;
      width: 100% !important;

      span {
        color: $color-white;
      }

      &:before {
        content: none !important;
      }

      &:last-child {
        padding: 16px 32px !important;
      }

      &:hover, &.active {
        background: rgba(243, 134, 56, 1);
        opacity: 1;
        span {
          color: $color-tertiary;
        }
      }
    }
  }

  &__ellipsis {
    display: none;
    position: relative;
    justify-content: center;
    cursor: pointer;

    &:active, &:hover, &.active {
      span {
        color: $color-tertiary;
      }
      img {
        filter: hue-rotate(193deg) saturate(100%) brightness(20%);
        z-index: 1;
      }
    }

    span {
      color: $color-white;
      margin-right: 16px;
    }
  }

 .navbar__list a.nav-link, &__ellipsis {
   color: $color-white;
   font-size: 17px;
   font-weight: 600;
   line-height: 24px;
   text-decoration: none;
   padding: 12px 32px;
   position: relative;
   width: max-content;
   text-align: center;

    &:hover {
      color: $color-tertiary;
    }

     span {
       position: relative;
       z-index: 2;
     }
  }

  .navbar__list a.nav-link::before,
  .mini-nav__ellipsis::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    transform: skewX(-25deg);
    opacity: 0;
    z-index: 1;
  }

  .navbar__list a.nav-link:hover::before,
  .navbar__list a.nav-link.active::before,
  .mini-nav__ellipsis:hover::before,
  .mini-nav__ellipsis.active::before {
    background: rgba(243, 134, 56, 1);
    opacity: 1;
  }

  .navbar-toggler {
    .open-icon {
      filter: brightness(10);
    }
  }

  .navbar-toggler .closed-icon {
    display: none;
  }

  .navbar-toggler[aria-expanded="true"] .closed-icon {
    display: block;
  }

  .navbar-toggler[aria-expanded="true"] .open-icon {
    display: none;
  }

  &__tab {
    .mini-nav {
      width: 100%;

      .mini-nav__box {
        background-color: $color-tertiary;
        a.nav-item.nav-link {
          &:hover, &.active {
            background: $color-primary;
          }
        }
      }

      &__ellipsis {
        &:active, &:hover, &.active {
          span {
            color: $color-white;
          }
          img {
            filter: unset;
          }
        }
      }

      &::before {
        background-color: $color-tertiary;
      }

      .navbar__list a.nav-link:hover{
        color: $color-white;
      }

      .navbar__list a.nav-link:before,
      .mini-nav__ellipsis:before{
        background-color: #002834;
      }

      .navbar__list a.nav-link.active:before,
      .mini-nav__ellipsis.active:before {
        background-color: $color-primary;
      }

      .scroll-indicator-wrapper {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .scroll-indicator {
        height: 100%;
        width: 0;
        background-color: $color-secondary;
      }
    }

    &.sticky {
      .navbar-brand {
        &.for-desktop {
          display: none;
        }
      }
    }
  }

  @media #{$media-screen-mobile} {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    position: absolute;
    bottom: auto;
    top: -57px;
    transform: none;
    padding: .5rem 1rem;
    z-index: 3;
    background-color: $color-secondary;

    &::before {
      transform: skew(0);
    }

    &__wrapper {
      z-index: 8;

      &.sticky {
        position: fixed;
        top: 0;
        transition: all 0.3s ease-out;

        .mini-nav {
          min-width: 100%;
          background-color: $color-secondary;
          bottom: unset;
          top: 0;
        }
      }

      &.add-top {
        top:62px;
      }

      .back-to-top__wrapper {
        display: none !important;
      }

      .scroll-indicator-wrapper {
        display: none !important;
      }
    }

    a.navbar-brand {
      font-weight: 600;
      padding: .5rem 1rem;

      &.dimmed {
        opacity: .4;
      }
    }

    .navbar-brand {
      &.for-desktop {
        display: none!important;
      }

      &.for-mobile {
        display: block;
      }
    }

    .navbar-nav {
      margin-top: 22px;
    }

    .navbar__list a.nav-link {
      padding: .5rem 1rem;
      font-size: 21px;
      font-weight: 700;
      line-height: 28px;

      &::before {
        display: none;
      }

      &:active, &:hover {
        color: $color-tertiary;
      }

      &.active {
        color: $color-tertiary;
      }
    }

    &__tab {
      .mini-nav {
        width: 100%;
        min-width: 100%;

        &::before {
          transform: skew(0);
        }
      }

      .navbar__list a.nav-link {
        &:active, &:hover {
          color: $color-secondary;
        }

        &.active {
          color: $color-secondary;
        }
      }

      &.sticky {
        .mini-nav {
          background-color: $color-tertiary;
        }
      }
    }
  }
}
