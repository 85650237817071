.content-block {
  text-align: center;
  padding: calc($grid-gutter-width / 2);
  text-decoration: none;
  cursor: auto;

  &.has-border {
    border: $thick-border-width solid transparent;
    cursor: pointer;

    &:hover {
      border: 6px solid $gray-300;
      border-radius: 45px;
    }
  }

  &__image img {
    max-width: 100%;
    display: block;
    margin: auto;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__title {
    padding: 1rem 0;
  }

  &__desc {
    color: $color-text-body;
    font-weight: normal;

    & a {
      color: $color-tertiary;
    }
  }

  @media #{$media-lg} {
    &__desc {
      padding-left: $grid-gutter-width - 10;
      padding-right: $grid-gutter-width - 10;
    }
  }

  @media #{$media-screen-mobile} {
    flex-direction: row;
    text-align: left;

    &__image {
      text-align: center;
      flex: 0 0 25%;
      max-width: 25%;
    }

    &__list-item {
      padding-left: $grid-gutter-width;
      flex: 0 0 75%;
      max-width: 75%;
    }

    &__title {
      padding-top: 0;
    }
  }
}
