.bg-img-text {
  position: relative;
  overflow: hidden;

  &--content-light {
    @media #{$media-md} {
      color: white;
    }
  }

  &--info-right {
    .bg-img-text__info {
      justify-content: flex-end;
    }
  }

  &--info-left {
    .bg-img-text__info {
      justify-content: flex-start;
    }
  }

  &__info {
    @include make-container-fluid();
    position: absolute;
    bottom: 0;
    color: $color-tertiary;
    height: 100%;

    @media #{$media-md} {
      top: 50%;
      left: 50%;
      bottom: unset;
      transform: translate(-50% , -50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
  }

  &__info-image {
    position: relative;
    left: 0;
    margin-bottom: 18px;

    @media #{$media-large-desktop} {
      margin-bottom: 28px;
    }

    .bg-img-text__info-image--fluid {
      width: 100%;
      height: auto;

      @media #{$media-screen-tablet-desktop} {
        width: 50%;
      }
    }

    @media #{$media-large-desktop} {
      left: - percentage(calc(3/12));
    }
  }

  &__image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: relative;
  }

  &__info-inner {
    @include column-gutter();
    @extend .mb-component;
    padding-top: 80px;
    padding-bottom: 64px;
    position: relative;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: normal;

    p {
      font-size: 14px;
      font-weight: 600;
    }

    .btn {
      width: fit-content;
    }

    @media #{$media-lg} {
      flex: 0 0 percentage(calc(5/12));
      justify-content: center;
      margin-bottom: 0;

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &__btn {
    @extend .btn;
    background-color: $color-secondary;
    margin-top: .5rem;
    color: $color-white;
    padding: 15px 24px;
  }

  @media #{$media-screen-mobile} {
    &__image img {
      position: static;
      width: 100%;
      height: auto;
      transform: none;
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &--info-right {
      .bg-img-text__info-inner {
        padding-right: 30px;
      }

      .bg-img-text__image {
        background-position: 30%;
      }
    }

    &--info-left {
      .bg-img-text__info-inner {
        padding-left: 30px;
      }

      .bg-img-text__image {
        background-position: 50%;
      }
    }
  }

  .js-slide-up {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, 40);
    transition: opacity 1.5s ease, transform 1s ease;

    &.slide-up {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  .js-slide-down {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, -10);
    transition: opacity 1.5s ease, transform 1.5s ease;

    &.slide-down {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
}
