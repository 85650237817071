$footer-padding: 50px 0 30px 0;

.footer {
  padding: $footer-padding;
  background-color: $color-tertiary;
  font-family: $font-montserrat;

  &__discover {
    padding: 100px 0 30px 0;

    @media #{$media-md} {
      padding: 130px 0 30px 0;
    }
  }

  &__nav, &__info, &__socMed {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-max-width;
  }

  &__socMed {
    display: flex;
    margin-bottom: 48px;
    color: $color-white;
    flex-direction: column;
    text-align: center;

    @media #{$media-md} {
      flex-direction: row;
      justify-content: center;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;

      @media #{$media-md} {
        font-size: 18px;
        padding-right: 16px;
        font-weight: normal;
        margin-bottom: 0;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    @media #{$media-md} {
      flex-direction: row;
    }
  }

  &__menu {
    @include column-gutter();
    flex: percentage(calc(6/12)) 1 0;
    margin-bottom: 2rem;

    &:last-child {
      border: none;
    }

    @media #{$media-screen-mobile} {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin: 0 15px 16px 15px;
      padding: 0;
      position: relative;

      .toggle-menu.open .footer__menu-icon {
        transform: rotate(180deg);
      }
    }

    @media #{$media-md} {
      flex: percentage(calc(3/12)) 1 0;
    }

    @media #{$media-xl} {
      flex: percentage(calc(2/12)) 1 0;
    }
  }

  &__menu-group {
    color: $color-white;
    font-size: map-get($font-size, "size-6");
    padding-bottom: 16px;
    font-weight: bold;
  }

  &__menu-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    transition: transform 0.3s;

    @media #{$media-md} {
      display: none;
    }
  }

  &__submenu {
    margin-bottom: 16px;

    @media #{$media-md} {
      margin-bottom: 0;
    }
  }

  &__menu-item {
    color: $color-primary;
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    font-size: map-get($font-size, "size-7");

    &:hover {
      text-decoration: underline;
    }
  }

  &__follow {
    height: 32px;

    & a {
      background: transparent;
      color: $color-white;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      margin-right: 24px;
      width: 32px;
      height: 32px;

      &:before {
        width: 32px;
        height: 32px;
        display: block;
      }

      &.facebook:before {
        content: url('../assets/images/social-media-icon/Facebook.svg');
      }

      &:hover {
        &.facebook:before {
          content: url('../assets/images/social-media-icon/Facebook-green.svg');
        }
      }

      &.instagram:before {
        content: url('../assets/images/social-media-icon/Instagram.svg');
      }

      &:hover {
        &.instagram:before {
          content: url('../assets/images/social-media-icon/Instagram-green.svg');
        }
      }

      &.linkedIn:before {
        content: url('../assets/images/social-media-icon/LinkedIn.svg');
      }

      &:hover {
        &.linkedIn:before {
          content: url('../assets/images/social-media-icon/LinkedIn-green.svg');
        }
      }

      &.whatsApp:before {
        content: url('../assets/images/social-media-icon/WhatsApp.svg');
      }

      &:hover {
        &.whatsApp:before {
          content: url('../assets/images/social-media-icon/WhatsApp-green.svg');
        }
      }

      &.youTube:before {
        content: url('../assets/images/social-media-icon/YouTube.svg');
      }

      &:hover {
        &.youTube:before {
          content: url('../assets/images/social-media-icon/YouTube-green.svg');
        }
      }

      &.tikTok:before {
        content: url('../assets/images/social-media-icon/TikTok.svg');
      }

      &:hover {
        &.tikTok:before {
          content: url('../assets/images/social-media-icon/TikTok-green.svg');
        }
      }
    }
  }

  &__copyright {
    font-size: map-get($font-size, "size-7");
    color: $color-white;
    padding-top: 1rem;
  }

  &__info {
    display: flex;
    flex-direction: column;

    @media #{$media-md} {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__content {
    @include column-gutter();
    order: 2;
    margin-top: 1rem;
    text-align: center;

    @media #{$media-md} {
      flex-basis: percentage(calc(6/12));
      order: 2;
      text-align: right;
    }
  }

  &__brand {
    @include column-gutter();
    order: 1;
    display: flex;
    align-items: center;
    align-self: center;

    @media #{$media-md} {
      flex-basis: percentage(calc(6/12));
      order: 2;
      margin-bottom: 0;
      text-align: left;
    }

    &-app {
      .d-inline-block {
        display: block!important;
      }

      @media #{$media-md} {
        .d-inline-block {
          display: inline-block!important;
        }
      }
    }
  }

  &__brand img {
    width: 80px;
    height: auto;
    margin-right: 16px;
    filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.20));

    @media #{$media-md} {
      max-width: 80px;
      width: 100%;
    }
  }
}
