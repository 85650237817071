$masthead-title-max: strip-unit(map-get($font-size, "size-1")) * $font-base;
$masthead-title-min: strip-unit(map-get($font-size, "size-4")) * $font-base;
$masthead-min-height: 369px;
$masthead-eclipse-size: 20%;
$masthead-info-width: 50%;

$masthead-desc-bg-colors: (
  "theme-1": $color-gray-100,
  "theme-2": $color-gray-100,
  "theme-3": $color-tertiary
);

$masthead-theme-colors: (
  "theme-1": $color-primary,
  "theme-2": $color-secondary,
  "theme-3": $color-secondary
);

$masthead-desc-colors: (
  "theme-1": $color-text-body,
  "theme-2": $color-text-body,
  "theme-3": $color-white,
);


$masthead-btn-colors: (
  "theme-1": $color-tertiary,
  "theme-2": $color-primary,
  "theme-3": $color-primary
);

.masthead {
  position: relative;
  overflow: hidden;
  font-family: $font-montserrat;

  &__placeholder {
    display: none;
    padding-bottom: calc(#{percentage(calc(450/1440))}); //calc(#{percentage(325/600/2)} + 120px);

    @media #{$media-xl} {
      display: block;
    }
  }

  &__image {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }


  @each $color, $value in $masthead-theme-colors {
    &--#{$color} {
      background: map-get($masthead-desc-bg-colors, $color);
      color: $color-white;

      .masthead__title {
        @include text-block($value, calc($grid-gutter-width / 2));
      }

      .masthead__inner {

        .masthead__info {
          & p {
            color: map-get($masthead-desc-colors, $color);
          }

          .masthead__btn {
            @include button-variant(
              map-get($masthead-btn-colors, $color), map-get($masthead-btn-colors, $color),
              lighten(map-get($masthead-btn-colors, $color), $color-hover-lighten), lighten(map-get($masthead-btn-colors, $color), $color-hover-lighten),
              lighten(map-get($masthead-btn-colors, $color), $color-active-lighten), lighten(map-get($masthead-btn-colors, $color), $color-active-lighten)
            );
            &:hover{
              color: $color-white;
            }
          }
        }
      }
    }
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: flex;

    &--light {
        justify-content: flex-end;
    }

    @media #{$media-md} {
      min-height: $masthead-min-height;

      &:before {
        content: '';
        position: absolute;
        left: $masthead-info-width - calc($masthead-eclipse-size * 3/4);
        top: 50% - calc($masthead-eclipse-size / 4);
        width: $masthead-eclipse-size;
        height: 150%;
        border-radius: 50%;
        transform: translate(0, -50%);
        z-index: 2;
      }

      .masthead--no-eclipse & {
        &:before {
          content: initial;
        }
      }
    }

    @media #{$media-xl} {
      position: absolute;
      width: 100%;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
  }

  &__info {
    @include column-gutter();
    z-index: 3;
    position: relative;
    flex: $masthead-info-width 0 0;
    align-self: center;
    padding-top: $component-vertical-padding;
    padding-bottom: $component-vertical-padding;

    @media #{$media-lg} {
      padding-right: 37.5px;
    }

    @media #{$media-xl} {
      padding-right: 75px;
    }
  }

  &__info-content {
    position: relative;
  }

  &__title {
    cursor: pointer;

    margin-bottom: 1rem;
    font-size: $masthead-title-min;

    @media #{$media-md} {
      font-size: calc(#{$masthead-title-min} + #{strip-unit($masthead-title-max - $masthead-title-min)} * (100vw - 768px) / (1200 - 768));
    }

    @media #{$media-xl} {
      font-size: $masthead-title-max;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn-tertiary;

    margin-top: 1rem;
  }

  @media #{$media-screen-mobile} {
    height: 100%;

    &__image {
      padding-bottom: 50%;
      position: static;
      width: 100%;
      height: auto;
    }

    &__info {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__image-mobile {
      & img {
        width: 100%;
        display: block;
        transform: none;
      }
    }
  }
}
