.js-plan-tab-container {
  position: relative;
}

.filter-plan-cards-tab {
  position: relative;

  &__container {
    max-width: none;
  }

  &__tab-item {
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    min-width: 200px;

    &:not(:first-child) {
      .filter-plan-cards-tab__item-text {
        border-left: 2px solid $color-secondary;
      }
    }

    &:hover {
      color: $color-tertiary;
      background: #F9CEBC;

      &:first-child {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }

      &:last-child {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }

  &__item-text {
    @extend .font-semi-bold;
    padding: 10px 20px;
  }

  &__wrapper {
    display: block;
    width: auto;
    margin: auto;
    text-align: -webkit-center;

    & .active {
      .filter-plan-cards-tab__item-text {
        background: $color-secondary;
      }

      &:first-child {
        .filter-plan-cards-tab__item-text {
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }
      }

      &:last-child {
        .filter-plan-cards-tab__item-text {
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
        }
      }

      .filter-plan-cards__item-text {
        color: $color-white;
      }
    }
  }

  &__wrapper-item {
    display: flex;
    justify-content: center;
    background: white;
    border-radius: 50px;
    border: 2px solid $color-secondary;
  }

  &__heading {
    text-align: center;
    margin: 16px 0;

    &-text {
      font-size: 24px;
    }
  }

  &__dropdown-menu {
    width: max-content;
  }

  &__url {
    font-weight: 700;
    color: $color-secondary;
    text-decoration: none;
    position: absolute;
    right: 0;
    bottom: 12px;
  }

  @media #{$media-not-desktop} {
    margin-left: -15px;
    margin-right: -15px;
    position: unset;

    &__container {
      overflow: auto;
      padding: 0;
    }

    &__tab-item-text {
      padding: 10px;
    }

    &__wrapper {
      position: relative;
    }

    &__heading {
      &-text {
        font-size: 22px;
      }
    }

    &__url {
      bottom: -40px;
      right: 0;
      left: 0;
      text-align: center;
    }
  }
}
