.newsletter-form {
  &__top {
    background: #DAF2EF;
  }

  &__bottom {
    background: #b5e5df;
    margin-right: -32px;
    margin-left: -32px;
    margin-bottom: -20px;
  }

  &__head {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    padding-top: 32px;
  }

  &__input-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__email {
    margin-bottom: 32px;

    input {
      border-radius: 50px;
      background-color: $color-white;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border: none;
    }

    &.ws-invalid {
      input {
        border: 1px solid #F00;
        color: #F00;
      }

      .ws-errorbox p {
        color: #F00;
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }

  &__check {
    margin-bottom: 32px;

    input[type="checkbox"] {
      margin-top: 5px;
      width: 32px;
      height: 32px;
      position: absolute;

      &::before {
        width: 32px;
        height: 32px;
        background: $color-white;
      }
    }

    input[type="checkbox"]:checked {
      &::after {
        height: 32px;
        width: 32px;
        font-size: 20px;
        color: $color-white;
        background: $color-primary;
        padding-left: 8px;
      }
    }

    .ws-invalid {
      input[type="checkbox"] {
        &::before {
          border: 1px solid #F00;
        }
      }
    }

    &-item {
      padding: 32px 32px 0;
      font-size: 16px;
      font-weight: 400;

      .text-body {
        position: relative;
        left: 44px;
        top: 10px;
      }

      .ws-errorbox p {
        display: none !important;
      }
    }
  }

  &__submit {
    position: relative;
    bottom: -20px;
    width: 100%;
  }

  &__radio {
    margin-bottom: 32px;

    & input[type="radio"] {
      &::before {
        width: 32px;
        height: 32px;
        background: $color-white;
        border-radius: 100%;
        border: 2px solid #ccc;
      }
    }

    & input[type="radio"]:checked {
      &::after {
        width: 20px;
        height: 20px;
        background: $color-border-box-price-plans;
        border-radius: 100%;
      }
    }

    &-item {
      margin-left: 10px;

      .ws-errorbox {
        left: -10px;

        p {
          color: #F00;
          font-size: 14px;
          margin-top: 8px;
        }
      }

      &.ws-invalid {
        & input[type="radio"] {
          &::before {
            border: 1px solid #f00;
          }
        }

        .ws-errorbox {
          left: -10px;

          p {
            color: #F00;
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }

    &-item label {
      margin-left: 16px;
      margin-right: 40px;
      font-weight: 700;
      color: $color-primary;
      cursor: pointer;
    }
  }
}

.modal-newsletter {
  background-color: #DAF2EF;
}
