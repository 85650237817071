.masthead-slider {
  position: relative;
  overflow-x: clip;

  &__content {
    &.slick-slider.slick-dotted {
      margin-bottom: 50px;

      @media #{$media-md} {
        margin-bottom: 0;
      }
    }

    .slick-current {
      border: none;
    }
  }

  &__desc-content{
    padding: 32px;
    width: 40%;
    background-color: $color-primary;
    position: absolute;
    bottom: 0;
    right: 88px;
    z-index: 1;

    .breadcrumb-box {
      display: none;
      position: absolute;
      background: rgba(0, 57, 73, 0.88);
      padding: 16px;
      z-index: 3;
      left: 155px;
      top: 55px;

      ul li span {
        display: block !important;
        padding: 5px 0;

        a {
          pointer-events: unset;
          font-weight: normal;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .breadcrumb-box a {
      display: block;
      margin: 5px 0;
    }
  }

  .slick-initialized {
    .slick-dots li button {
      width: 8px;
      height: 8px;
    }

    .slick-dots {
      text-align: right;

      li {
        width: 8px;
        height: 8px;
        margin: 0 14px;

        &:last-child {
          margin-right: 0;
        }

        button:before {
          width: 8px;
          height: 8px;
          background-color: $color-white;
          border: none;
        }

        &:hover {
          button:before {
            background-color: $color-tertiary;
          }
        }

        &.slick-active button:before {
          background-color: $color-tertiary;
          width: 35px;
          border-radius: 50px;
          transform: translateX(-35%);
          transition: .3s;

        }
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-list.draggable {
    width: 100%;
  }

  .slick-prev, .slick-next {
    display: block;
    position: absolute;
    top: unset;
    bottom: -66px;
    z-index: 3;
    width: 32px;
    height: 32px;
    border-radius: 0;

    &:before {
      @extend %icon;
      position: absolute;
      bottom: 0;
      transform: translate(-50%);
      font-size: 20px;
      color: $color-tertiary;
      opacity: 1;
    }
  }

  .slick-prev {
    left: 0;

    &::before {
      content: url('../assets/images/masthead/arrow-left.svg');
    }

    &:hover {
      &::before {
        content: url('../assets/images/masthead/arrow-navy-left.svg');
      }
    }
  }

  .slick-next {
    left: 64px;

    &::before {
      content: url('../assets/images/masthead/arrow-right.svg');
    }

    &:hover {
      &::before {
        content: url('../assets/images/masthead/arrow-navy-right.svg');
      }
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &__desc-content {
      width: 50%;
    }
  }

  @media #{$media-screen-mobile} {
    height: auto;

    &__desc-content {
      position: relative;
      width: 100%;
      right: 0;
    }

    & .slick-track {
      background: transparent;
    }
  }
}

.masthead-content {
  position: relative;
  font-family: $font-montserrat;

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__placeholder {
    @media #{$media-md} {
      display: block;
      padding-bottom: calc(#{percentage(calc(576/1440))}); //calc(#{percentage(325/600/2)} + 120px);
    }

    @media #{$media-screen-tablet} {
      padding-bottom: calc(#{percentage(calc(860/1440))}); //calc(#{percentage(325/600/2)} + 120px);
    }
  }

  &__image {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 130%;
    background-size: cover;
    background-position: center;
    transform: translate3d(0, 0, 0);

    @media #{$media-screen-tablet-desktop} {
      top: -20px;
    }
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: block;

    @media #{$media-md} {
      &:before {
        content: '';
        position: absolute;
        left: $masthead-info-width - calc($masthead-eclipse-size * 3/4);
        top: 50% - calc($masthead-eclipse-size / 4);
        width: $masthead-eclipse-size;
        height: 150%;
        border-radius: 50%;
        transform: translate(0, -50%);
        z-index: 2;
      }

      .masthead--no-eclipse & {
        &:before {
          content: initial;
        }
      }
    }

    @media #{$media-xl} {
      width: 100%;
    }
  }

  &__info {
    z-index: 3;
    position: relative;
    //width: $masthead-info-width;

    &.single {
      padding-top: 16px;
    }
  }

  &__info-content {
    position: relative;
  }

  &__breadcrumbs {
    color: $color-white;
    font-size: 14px;
    font-weight: 700;
    display: block;

    &-item {
      a {
        color: $color-white;
        text-decoration: none;
      }

      &.active {
        font-weight: 400;
        color: $color-white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
       }

      &:last-child {
        a {
          font-weight: bold;
          pointer-events: none;
        }
      }
    }

    .icon-slash {
      font-size: 20px;
      margin: 0 6px;
      position: relative;
      top: 2px;
    }

    .show-more {
      position: relative;
      top: -6px;
      cursor: pointer;
      letter-spacing: 4px;
      color: $color-tertiary;

      &:after {
        content: '';
        display: inline-block;
        border-bottom: 1px solid $color-tertiary;
        width: 19px;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }
  }

  &__title {
    cursor: pointer;
    color: $color-white;
    margin-bottom: 1rem;
    font-size: map-get($font-size, "size-2");

    @media #{$media-md} {
      font-size: calc(#{$masthead-title-min} + #{strip-unit($masthead-title-max - $masthead-title-min)} * (100vw - 768px) / (1200 - 768));
    }

    @media #{$media-xl} {
      font-size: $masthead-title-max + 5;
    }
  }

  &__desc {
    color: $color-white;
    font-size: map-get($font-size, "size-5");
  }

  &__link {
    font-size: map-get($font-size, 'size-5');
    font-weight: 700;
    line-height: 24px;
    color: $color-tertiary;
    text-decoration: none;

    &:hover{
      color: $color-tertiary;
      text-decoration: underline;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn-tertiary;

    margin-top: 1rem;
  }

  @media #{$media-screen-mobile} {
    height: 100%;

    &__desc,  &__link {
      font-size: map-get($font-size, "size-6");
    }

    &__image {
      top: -80px;
      height: 130%;
      background-position: 25%;
    }

    &__info {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__image-mobile {
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
        display: block;
        transform: none;
      }
    }
  }
}
