$banner-title-max: strip-unit(map-get($font-size, "size-2")) * $font-base;
$banner-title-min: strip-unit(map-get($font-size, "size-4")) * $font-base;

$banner-theme-colors: (
  "theme-1": $color-primary,
  "theme-2": $color-secondary,
  "theme-3": $color-secondary
);

$banner-desc-colors: (
  "theme-1": $color-text-body,
  "theme-2": $color-text-body,
  "theme-3": $color-white
);

$desc-background-colors: (
  "theme-1": $color-gray-100,
  "theme-2": $color-gray-100,
  "theme-3": $color-tertiary
);

$banner-btn-colors: (
  "theme-1": $color-tertiary,
  "theme-2": $color-primary,
  "theme-3": $color-primary
);

.full-width-banner {
  position: relative;
  overflow: hidden;

  @each $color, $value in $banner-theme-colors {
    &--#{$color} {
      @media #{$media-screen-mobile} {
        background: map-get($desc-background-colors, $color);
      }

      .full-width-banner__title {
        margin: 1rem 0;
        @include text-block($value, calc($grid-gutter-width / 2));
      }

      .full-width-banner__info {
        & p {
          color: map-get($masthead-desc-colors, $color);
        }

        .full-width-banner__btn {
          @include button-variant(
            map-get($masthead-btn-colors, $color), map-get($masthead-btn-colors, $color),
            lighten(map-get($masthead-btn-colors, $color), $color-hover-lighten), lighten(map-get($masthead-btn-colors, $color), $color-hover-lighten),
            lighten(map-get($masthead-btn-colors, $color), $color-active-lighten), lighten(map-get($masthead-btn-colors, $color), $color-active-lighten)
          );
          &:hover{
            color: $color-white;
          }
        }
      }
    }
  }

  &--content-light {
    @media #{$media-md} {
      color: white;
    }
  }

  &--info-right {
    .full-width-banner__info {
      justify-content: flex-end;
    }
  }

  &--info-left {
    .full-width-banner__info {
      justify-content: flex-start;
    }
  }

  &__info {
    @include make-container-fluid();

    @media #{$media-md} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
  }

  &__image {
    position: relative;

    @media #{$media-md} {
      height: auto;
      padding-bottom: percentage(calc(450/1440));
    }
  }

  &__image img {
    position: absolute;
    width: auto;
    height: 100%;
    transform: translate(calc((100vw - 767px) / 7.5), 0);

    @media #{$media-md} {
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0);
    }
  }

  &__info-inner {
    @include column-gutter();
    @extend .mb-component;
    position: relative;
    align-self: center;

    @media #{$media-md} {
      flex: 0 0 percentage(calc(6/12));
      margin-bottom: 0;
    }

    @media #{$media-lg} {
      flex: 0 0 percentage(calc(5/12));
    }

    // > * {
    //   margin-top: 1rem;
    //   margin-bottom: 0;
    // }
  }

  &__title {
    cursor: pointer;

    margin-bottom: 1rem;
    font-size: $banner-title-min;

    @media #{$media-md} {
      margin-top: 0;
      font-size: calc(#{$banner-title-min} + #{strip-unit($masthead-title-max - $banner-title-min)} * (100vw - 768px) / (1200 - 768));
    }

    @media #{$media-xl} {
      font-size: $banner-title-max;
    }
  }

  &__btn {
    @extend .btn;

    margin-top: 1rem;
  }

  @media #{$media-screen-mobile} {
    &__image img {
      position: static;
      width: 100%;
      height: auto;
      transform: none;
    }
  }
}
