.usp-bar {
  &__white {
    background-color: $color-white;
  }

  &__green {
    background-color: #D0EFEB;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  &__icon {
    margin-right: 15px;

    &-img {
      width: 40px;
      height: auto;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 700;
  }

  @media #{$media-screen-mobile} {
    &__text {
      font-size: 16px;
    }
  }
}
