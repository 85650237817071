.career-form-submit {
  @include fluid-padding-bottom($grid-gutter-width * 2, 150px);

  @include fluid-padding-top($grid-gutter-width, $grid-gutter-width * 2);

  .input-error {
    color: $color-errors;
  }

  .form-group.ws-invalid {
    .user-error {
      border-color: $color-errors;
    }
  }

  .input-error {
    color: $color-errors;
    display: block;
    margin-top: calc($grid-gutter-width / 2);;
  }

  .input-success {
    color: $color-primary;
    display: block;
    margin-top: calc($grid-gutter-width / 2);
    margin-bottom: calc($grid-gutter-width / 4);
  }

  .input-limit {
    color: $color-tertiary;
    display: block;
    margin-top: calc($grid-gutter-width / 2);
    margin-bottom: calc($grid-gutter-width / 4);
  }

  .error-recaptcha {
    color: $color-errors;
    margin-top: calc($grid-gutter-width / 2);;
  }

  .ws-errorbox p {
    margin-top: 10px;
    margin-bottom: 0;
  }
  &__title {
    padding-bottom: $grid-gutter-width;
  }

  &__input-title {
    padding-bottom: 5px;
  }

  &__full-name-group {
    position: relative;
    display: flex;
  }

  &__wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  &__name {
    color: $color-primary;
    font-weight: 700;
    text-decoration: underline;

    & span {
      word-break: break-all
    }
  }

  &__full-name,
  &__email,
  &__confirm-email,
  &__mobile-number,
  &__single-line,
  &__multiple-line,
  &__number,
  &__multiple-line,
  &__telephone,
  &__date-time {
    & input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border-radius: 80px;
    }

    .user-error {
      border: 1px solid #f00;
      color: #f00;
    }

    .user-success {
      border: 1px solid $color-primary;
    }
  }

  &__full-name,
  &__email,
  &__confirm-email,
  &__mobile-number,
  &__single-line,
  &__multiple-line,
  &__number,
  &__multiple-line,
  &__telephone,
  &__date-time,
  &__telephone,
  &__check-box,
  &__select-dropdown,
  &__check-label,
  &__radio-button {
    padding-bottom: calc($grid-gutter-width / 2);
  }

  &__full-name,
  &__email,
  &__confirm-email,
  &__mobile-number,
  &__single-line,
  &__multiple-line,
  &__number,
  &__multiple-line,
  &__telephone,
  &__date-time,
  &__telephone,
  &__check-box,
  &__select-dropdown,
  &__upload {
    padding-left: 0;
  }

  &__check-label {
    line-height: 32px;
  }

  &__check-label-item, &__radio-button-item, &__check-box {
    margin-bottom: 28px;
  }

  &__radio-button-item label {
    margin-left: 16px;
    margin-right: 40px;
    font-weight: 700;
    color: $color-primary;
    cursor: pointer;
  }

  &__radio-button-item input {
    margin-bottom: 6px;
  }

  &__multiple-line {

    & textarea {
      max-width: 100%;
      width: 100%;
      height: $grid-gutter-width * 5;
      border-radius: 8px;
      border: 1px solid $color-gray-300;
      padding: 0 10px;

      &:focus {
        color: $color-text-body;
        outline: none;
      }
    }
  }

  &__select-dropdown {
    & .input-group {
      position: relative;
      border: 1px solid $color-gray-300;
      border-radius: 30px;
    }

    select::-ms-expand {
      display: none;
    }

    .input-group select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      height: 40px;
      float: right;
      margin: 5px 0px;
      border-radius: 4px;
      border: 1px solid $color-gray-300;
      padding: 0 10px;
      background: $color-white;
      -ms-word-break: normal;
      word-break: normal;

      &:focus {
        color: $color-text-body;
        outline: none;
      }
    }
  }

  &__full-name,
  &__check-number {
    .ws-invalid {
      margin-bottom: 40px;

      @media #{$media-screen-mobile} {
        margin-bottom: 65px;
      }
    }

    .ws-errorbox {
      position: absolute;
      bottom: -30px;
      left: 145px;

      @media #{$media-screen-mobile} {
        left: 130px;
      }
    }

    .dropdown {
      display: inline-block;
      position: relative;
      height: 40px;
      width: 170px;
      margin-right: 18px;
      border-left: none;
      border: 1px solid $color-gray-300;
      border-radius: 80px;

      .ws-errorbox {
        position: absolute;
        left: 0;
        bottom: unset;

        p {
          margin: 0;
        }
      }
    }

    .dropdown-select {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 9px 8px 6px 11px;
      height: 40px;
      line-height: 14px;
      text-shadow: 0 1px $color-white;
      background: rgba(0, 0, 0, 0) !important;
      border: 0;
      border-radius: 0;
      -webkit-appearance: none;
      color: $color-text-body;
      font-weight: 600;
      &::-ms-expand {
        display: none;
      }
    }

    .dropdown-select:focus {
      z-index: 3;
      width: 100%;
      color: $color-text-body;
      outline: none;
      outline-offset: -2px;
    }

    .dropdown-select > option {
      margin: 3px;
      padding: 6px 8px;
      text-shadow: none;
      background: $color-white;
      border-radius: 3px;
      cursor: pointer;
    }

    .lt-ie9 .dropdown {
      z-index: 1;
    }

    .lt-ie9 .dropdown-select {
      z-index: -1;
    }

    .lt-ie9 .dropdown-select:focus {
      z-index: 3;
    }

    @-moz-document url-prefix() {
      .dropdown-select {
        padding-left: 6px;
      }
    }

    & input {
      border-radius: 80px;
    }
  }

  &__full-name {
    .dropdown select {
      padding-left: calc($grid-gutter-width / 2);
    }
  }

  &__date-time,
  &__select-dropdown {
    margin-right: 50%;
  }

  &__checked,
  &__check-box,
  &__check-label {
   padding-bottom: calc($grid-gutter-width / 2);;

    & label {
      cursor: pointer;
      font-size: 16px;
      margin-left: 15px;
    }

    input[type="checkbox"] {
      margin-top: 5px;

      &::before {
        width: 32px;
        height: 32px;
        background: $color-white;
        border: 2px solid #ccc;
      }

      &.user-success {
        &::before {
          border: 2px solid $color-primary;
        }
      }
    }

    input[type="checkbox"]:checked {
      &::after {
        content: url("../assets/images/check-ic.svg");
        height: 22px;
        width: 22px;
        color: $color-white;
        background: transparent;
        top: 40%;
      }
    }
  }

  &__checked {
    margin-top: calc($grid-gutter-width / 2);;
  }

  &__radio-button {
   & input[type="radio"] {
      margin-top: 6px;

      &::before {
        width: 32px;
        height: 32px;
        background: $color-white;
        border-radius: 100%;
        border: 2px solid #ccc;
      }
    }

   & input[type="radio"]:checked {
      &::after {
        width: 20px;
        height: 20px;
        background: $color-border-box-price-plans;
        border-radius: 100%;
      }
    }
  }

  &__date-time {
    position: relative;

    & span {
      position: absolute;
      top: 40px;
      right: 25px;
      font-size: 20px;
    }
  }

  &__submit {
    margin-top: 50px;

    .btn-white {
      background-color: #fff;
      border: 1px solid #eb6028;
      margin-right: 16px;
    }
  }

  &__upload {
    margin: 15px 0;
  }

  &__upload-title {
    margin-bottom: calc($grid-gutter-width / 2);;
  }

  &__upload-button {
    & label {
      cursor: pointer;
      position: relative;

      &::after {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: transparent url('../assets/images/upload-ic.svg') no-repeat center;
      }
    }

    input[type="file"] {
      position: absolute;
      opacity: 0;
    }

    & p {
      color: $color-errors;
      margin-top: 5px;
    }
  }

  &__item {
    margin: 5px 0;
  }

  &__delete {
    text-decoration: underline;
    color: $color-primary;
    cursor: pointer;
    margin-left: 10px;
  }

  &__check-number {
    position: relative;
    display: flex;

    & input {
      border-radius: 80px;
    }

    & span {
      position: absolute;
      left: 0;
      height: 40px;
      border: 1px solid $color-gray-300;
      border-radius: 4px 0 0 4px;
      border-left: none;
      width: $grid-gutter-width * 2;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
    }
  }

  &__group {
    margin-bottom: $grid-gutter-width;
  }

  @media #{$media-screen-mobile} {
    &__full-name,
    &__single-line,
    &__multiple-line,
    &__email,
    &__confirm-email,
    &__number,
    &__telephone,
    &__date-time,
    &__select-dropdown {
      margin-right: 0;
    }

    &__item-flex {
      display: flex;
      flex-direction: row;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      justify-content: flex-start;
    }
  }
}
