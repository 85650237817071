.modal {
  padding: 0 17px !important;

  &-header {
    position: relative;
    display: block;
    text-align: left;
    padding: 1rem 0;
    border: none;
  }

  h5 {
    margin-top: 5px;
  }

  &-title {
    font-size: 39px;
    color: $color-primary;
  }

  &-body {
    padding: 20px 0;
  }

  &-dialog {
    max-width: 750px;
  }

  &-content {
    padding: 0 32px;
    color: $color-text-body;
    border-radius: 0;
  }

  &-close {
    z-index: 1;
    position: absolute;
    background: transparent;
    border: none;
    right: 32px;
    top: 32px;

    .icon-close:before {
      font-size: 20px;
      font-weight: bold;
      color: $color-tertiary;
      cursor: pointer;
    }

    @media #{$media-not-desktop} {
      right: 10px;
      top: 10px;
    }
  }

  &-backdrop {
    &.show {
      opacity: 1;
      background: rgba(0, 0, 0, 0.88);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    }
  }

  .closed-icon {
    cursor: pointer;

    @media #{$media-not-desktop} {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(122deg) brightness(105%) contrast(101%);
    }
  }
}
