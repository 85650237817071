.plan-benefits {
  &__title {
    font-size: 20px;
    margin-bottom: 12px;

    @media #{$media-screen-mobile} {
      font-size: 18px;
    }
  }
}
