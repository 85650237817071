.long-cta {
  text-align: -webkit-center;

  @media #{$media-not-desktop} {
    padding: 0 16px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    background-color: $color-tertiary;
    border-radius: 50px;
    max-width: 991px;

    @media #{$media-not-desktop} {
      flex-direction: column;
      border-radius: 0;
    }
  }

  &__text {
    font-size: 24px;
    color: $color-white;
    font-weight: bold;
    padding: 22px 40px;
    display: flex;
    align-items: center;

    @media #{$media-not-desktop} {
      font-size: 16px;
      padding: 16px 24px 8px;
    }

    &--icon {
      display: flex;
      margin-right: 15px;
    }

  }

  &__btn {
    font-size: 18px;
    padding: 15px 24px 15px 80px;
    margin: 12px;
    border-radius: 50px;
    align-self: center;
    position: relative;
    cursor: pointer;

    &--primary {
      background-color: $color-primary;

      &:hover {
        background-color: #107D6F;
      }
    }

    &--secondary {
      background-color: $color-secondary;

      &:hover {
        background-color: #A7441C;
      }
    }

    &--footer {
      background-color: $color-secondary;

      &::before {
        background-color: #fff !important;
      }

      &:hover {
        background-color: #A7441C;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 65px;
      height: 105%;
      background-color: $color-tertiary;
      transform: skewX(-30deg);
      position: absolute;
      left: -8px;
      top: -2px;
    }

    a {
      color: $color-white;
      font-weight: bold;
      text-decoration: none;
    }

    @media #{$media-not-desktop} {
      font-size: 16px;
      padding: 16px;
      margin: 8px;
      border-radius: 0;
      align-self: normal;

      &::before {
        display: none;
      }
    }
  }

  &__footer {
    position: relative;
    z-index: 1;

    .long-cta__wrapper {
      background-color: $color-white;
      max-width: 80%;
      margin-top: 50px;

      .long-cta__text {
        color: $color-tertiary;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 24px;

        &--icon {
          margin-right: 8px;
        }
      }

      @media #{$media-not-desktop} {
        max-width: 100%;

        .long-cta__text {
          display: block;
          padding-top: 16px;
        }
      }
    }
  }
}
