.input-check-submit {
  margin-bottom: 24px;

  &__input {
    display: flex;
    flex-direction: row;

    &--max-width {
      align-items: center;
      justify-content: center;
    }
  }

  &__notification {
    font-size: 16px;
    margin-top: 5px;
  }

  &__notification.error {
    color: $color-errors;
  }

  &__notification.success {
    color: $color-primary;
  }

  & input {
    color: $color-tertiary;
    height: 46px;
    border: 1px solid #CCCCCC;
    max-width: 360px;
    border-radius: 80px 0 0 80px;
    padding-right: 24px;
    font-weight: bold;

    &::placeholder {
      font-weight: 700;
      color: #CCCCCC;
    }

    &.error {
      border-color: $color-errors;
      color: $color-errors !important;
      & + .input-check-submit__cta {
        border-color: $color-errors;
        background-color: #cbcbcb;
        color: $color-white;
        &:before {
          background-color: #cbcbcb;
        }
      }
    }

    &.success {
      border-color: $color-primary;
      color: $color-primary !important;
      & + .input-check-submit__cta {
        border-color: $color-primary;
      }
    }

    &:focus {
      color: $color-tertiary !important;
      box-shadow: none;
      border-color: $color-primary;
      & + .input-check-submit__cta {
        border-color: $color-primary;
        background: #D0EFEB;
        color: $color-primary;
        &:before {
          background-color: #D0EFEB;
        }
      }
    }
  }

  &__cta {
    font-weight: 700;
    min-width: 80px;
    background: #D0EFEB;
    border-radius: 0 80px 80px 0;
    border: 1px solid #CCCCCC;
    font-size: 16px;
    color: $color-primary;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      transform: skew(-30deg);
      height: 100%;
      width: 40px;
      background-color: #D0EFEB;
      display: flex;
      position: absolute;
      top: 0;
      left: -15px;
      z-index: -1;
    }

    & .icon-loading-spinner {
      &:before {
        display: inline-block;
        -webkit-animation:icon-spin 2s infinite linear;
        animation:icon-spin 2s infinite linear;
      }
    }

    span.js-text {
      left: -14px;
      position: relative;
    }

    @-webkit-keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
      }
      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
      }
    }
  }
}
