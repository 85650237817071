.interactive-referral {
  &__wrapper {
    display: flex !important;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    &:focus {
      outline: none;
    }
  }

  &__image {
    outline: none;

    & img {
      width: 100%;
    }
  }

  &__image,
  &__box-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
  }

  &__slider {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  &__box-item {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: $grid-gutter-width;
  }

  $this: &;

  &__item {
    border-left: 5px dotted $color-social-icon;
    padding-left: 30px;
    min-height: 145px;
    cursor: pointer;

    &:last-of-type {
      border-left: 5px dotted transparent;
    }

    &.active {
      #{$this}__title::before {
        border: 5px solid $color-secondary;
      }

      #{$this}__title,
      #{$this}__description {
        color: $color-bg-price-text;
      }
    }
  }

  &__title {
    position: relative;
    transition: all 0.3s;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      background: $color-white;
      border-radius: 100%;
      border: 5px solid $color-social-icon;
      top: 0;
      left: -48px;
      position: absolute;
    }
  }

  &__subtitle {
    width: 235px;
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: 30px;

    & span {
      color: $color-white;
      line-height: 2;
      background-color: $color-border-box-price-plans;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
      box-shadow: 10px 0 0 5px $color-border-box-price-plans, -10px 0 0 5px $color-border-box-price-plans;
    }
  }

  &__description {
    font-size: 16px;
    color: $color-text-body;
    transition: all 0.3s;

    & a {
      font-weight: bold;
      color: $color-tertiary;
    }
  }

  @media #{$media-screen-mobile} {
    &__wrapper {
      flex-direction: column;
      flex-wrap: wrap-reverse;
    }

    &__slider {
      order: 2;
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__image {
      padding-left: 60px;
      padding-right: 60px;
    }

    &__box-item {
      display: block;
      order: 1;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: calc($grid-gutter-width / 2);;
    }

    &__list-item {
      display: flex;
      flex-direction: row;
    }

    &__list-circle {
      display: block;
    }

    &__title {
      padding-top: 20px;

      &::before {
        display: none;
      }
    }

    &__subtitle {
      span {
        margin-left: 15px;
      }
    }

    &__circle {
      width: 30px;
      height: 30px;
      background: $color-white;
      border-radius: 100%;
      border: 5px solid $color-social-icon;
      z-index: 1;
      position: absolute;
      top: 0;
      cursor: pointer;

      &.active::before {
        border: 5px solid $color-secondary;
      }
    }

    &__item {
      border-left: 0 dotted transparent;
      padding-left: 30px;
      min-height: 145px;
      display: none;
      cursor: default;

      &:last-of-type {
        border-left: 1px dotted transparent;
      }

      &.active {
        display: block;
      }
    }

    &__item-circle {
      border: none;
      position: relative;
      min-height: 1px;
      padding: 15px 0 15px 0;

      &::after {
        position: absolute;
        top: 13px;
        content: '';
        width: 100%;
        border-top: 5px dotted $color-social-icon;
      };

      &:last-of-type {
        border-left: 0px dotted transparent;
      }
    }

    &__circle {
      &:nth-child(2n) {
        margin: auto;
        left: calc(50% - 15px);
      }

      &:nth-child(3n) {
        right: 0;
      }
    }

    &__circle.active {
      border: 5px solid $color-secondary;
    }
  }
}
