.referral-code {
  padding: 32px $grid-gutter-width 0 $grid-gutter-width;

  &__list {
    max-width: 706px;
  }

  &__title {
    font-size: 32px;
    margin-bottom: calc($grid-gutter-width - 6px);
  }

  .rich-text {
    max-width: 680px;
    margin-bottom: 32px;
  }
}
