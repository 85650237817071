.rewards {
  padding-bottom: 1px;

  &__item {
    outline: none;
    cursor: pointer;

    & > .component {
      flex-grow: 1;
      display: flex;
    }

    &.hidden{
      display: none;
    }

    &.visible {
      display: block;
    }
  }

  &__bot-line {
    margin-top: 15px;
  }

  & .slick-initialized {
    & .slick-track {
      display: flex;

      & .slick-slide {
        height: auto;
      }
    }
  }

  & .price-plan-card__border {
    &:hover {
      border-color: lighten(map-get($theme-colors, "primary"), $color-hover-lighten);
    }
  }

  & .price-plan-card__price-desc {
    text-align: left;
  }

  &__modal {
    & .modal-header {
      margin: 20px 0 0 0;
      padding: 0;
      border-bottom: none;
    }

    & .modal-close {
      right: -25px;
      top: -8px;

      & .icon-close:before {
        font-size: 15px;
      }
    }

    & .modal-title {
      @include fluid-type-mobile(16px, 22px);

      color: $color-tertiary;
      margin-top: calc($grid-gutter-width / 2);;
    }

    .rich-text {
      span {
        font-size: 14px;
      }
    }
  }

  &__pagination {
    display: none;
  }

  &-category{
    &__title{
      font-size: 39px;
      text-align: center;
      margin-bottom: 1rem;
      justify-content: center;

      .icon-plus,
      .icon-minus{
        display: none;
      }
    }

    &__list{
      display: block;
      overflow: auto;
      text-align: -webkit-center;
    }

    &__items{
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      font-size: 16px;
      padding: 0 calc($grid-gutter-width / 2);
      width: max-content;

      li{
        color: $color-tertiary;
        background-color: $color-white;
        border: 2px solid $color-secondary;
        border-left: none;
        padding: 10px 25px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;

        &:hover{
          color: $color-tertiary;
          background: #F9CEBC;
        }

        &.active{
          background-color: $color-secondary;
          border-color: $color-secondary;
          color: $color-white;
        }

        &:first-child {
          border-bottom-left-radius: 50px;
          border-top-left-radius: 50px;
          border-left: 2px solid $color-secondary;
        }

        &:last-child {
          border-bottom-right-radius: 50px;
          border-top-right-radius: 50px;
        }
      }
    }

    &__selected {
      display: none;
    }
  }

  &__cta {
    text-align: center;
    display: block;

    .btn-secondary {
      &:focus {
        background-color: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
      }
    }
  }

  .rich-text {
    .overlay__promo-code {
      color: $color-primary;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      position: relative;

      span {
        margin-right: 8px;
        font-size: 20px;
        font-weight: 700;
      }

      .overlay__promo-tooltip {
        display: none;
        position: absolute;
        background-color: #547A85;
        font-size: 12px;
        color: $color-white;
        border-radius: 16px;
        padding: 8px;
        right: 0;
        top: 36px;
        transition: opacity 1s;
        z-index: 2;

        &.show {
          opacity: 1;
        }
        &.hide {
          opacity: 0;
        }
      }

      &:hover {
        .overlay__promo-tooltip {
          display: block;
        }
      }
    }
  }

  @media #{$media-md} {
    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      flex-direction: column;
      flex: 0 0 percentage(calc(4/12));
      max-width: percentage(calc(4/12));
      margin-bottom: 30px;
    }
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(calc(4/12));
      max-width: percentage(calc(4/12));
    }

    & .col-desktop-3 {
      & .rewards__item {
        flex: 0 0 percentage(calc(4/12));
        max-width: percentage(calc(4/12));
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .rewards__item {
        flex: 0 0 percentage(calc(6/12));
        max-width: percentage(calc(6/12));
      }
    }

    .rewards-category {
      &__items{
        font-size:  $font-base;
      }
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(calc(6/12));
      max-width: percentage(calc(6/12));
    }

    & .col-tablet-3 {
      & .rewards__item {
        flex: 0 0 percentage(calc(4/12));
        max-width: percentage(calc(4/12));
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .rewards__item {
        flex: unset;
        max-width: percentage(calc(6/12));
      }
    }

  }

  @media #{$media-screen-tablet}{
    .rewards-category {
      margin-right: -1rem;
      margin-left: -1rem;
    }
  }

  @media #{$media-not-desktop} {
    overflow-x: clip;

    &__slider {
      .slick-list {
        overflow: visible;
      }
    }

    .rewards-category {
      &__list {
        text-align: -webkit-center;
      }

      &__title{
        display: flex;
        align-items: center;
        padding: 1rem 1rem;

        .icon-plus,
        .icon-minus{
          margin-left: auto;
        }
      }

      &__list{
        overflow: auto;
        padding-bottom: 1rem;
      }

      &__items{
        width: max-content;
      }
    }
  }

  @media #{$media-screen-mobile} {
    .container-fluid & {
      margin-left: - calc($grid-gutter-width / 2);;
      margin-right: - calc($grid-gutter-width / 2);;
    }

    &__pagination {
      padding-top: 24px;
      text-align: center;
      display: block;
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }

    &__cta {
      display: none;
    }

    &__slider {
      &.slick-dotted.slick-slider {
        margin-bottom: 50px;
      }
    }

    &__item {
      &--width {
        width: 330px;
      }
    }

    &-category{
      &__list {
        &.rewards-category__list-dropdown {
          margin: 0 16px;
          border-radius: 80px;
        }
      }

      &__items {
        padding: 16px;

        &.rewards-category__dropdown {
          width: -webkit-fill-available;
          background-color: $color-secondary;
          margin: 8px 16px 0 16px;
          border-radius: 32px;
        }
      }

      &__dropdown {
        flex-flow: column;
        position: absolute;
        z-index: 7;

        li {
          border-radius: 0 !important;
          background-color: transparent;
          border: none !important;
          text-align: left;
          padding: 12px;

          &.active {
            background-color: transparent;
          }
        }
      }

      &__selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        background-color: $color-secondary;
        color: $color-white;
        padding: 12px 24px;
        margin: 0 16px;
        border-radius: 50px;

        span {
          margin-right: 24px;
        }

        img {
          filter: brightness(10);
          transition: transform .3s;
        }

        &.open {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
