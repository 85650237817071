.old-career-form-submit {
  @include fluid-padding-bottom($grid-gutter-width * 2, 150px);

  @include fluid-padding-top($grid-gutter-width, $grid-gutter-width * 2);

  .input-error {
    color: $color-errors;
  }

  .form-group.ws-invalid {
    .user-error {
      border-color: $color-errors;
    }
  }

  .input-error {
    color: $color-errors;
    display: block;
    margin-top: calc($grid-gutter-width / 2);;
  }

  .error-recaptcha {
    color: $color-errors;
    margin-top: calc($grid-gutter-width / 2);;
  }

  .ws-errorbox p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__wrapper {
    & .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__title {
    padding-bottom: $grid-gutter-width;
  }

  &__input-title {
    padding-bottom: 5px;
  }

  &__full-name-group {
    position: relative;
  }

  &__wrapper {
    margin-left: 0;
    margin-right: 0;

    & .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__name {
    & span {
      word-break: break-all
    }
  }

  &__full-name,
  &__email,
  &__confirm-email,
  &__mobile-number,
  &__single-line,
  &__multiple-line,
  &__number,
  &__multiple-line,
  &__telephone,
  &__date-time {
    & input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
    }
  }

  &__full-name,
  &__email,
  &__confirm-email,
  &__mobile-number,
  &__single-line,
  &__multiple-line,
  &__number,
  &__multiple-line,
  &__telephone,
  &__date-time,
  &__telephone,
  &__check-box,
  &__select-dropdown,
  &__check-label,
  &__radio-button {
    padding-bottom: calc($grid-gutter-width / 2);;
  }

  &__check-label-item, &__radio-button-item, &__check-box {
    margin-bottom: calc($grid-gutter-width / 2);;
  }

  &__radio-button-item label {
    margin-left: 10px;
    cursor: pointer;
  }

  &__radio-button-item input {
    margin-bottom: calc($grid-gutter-width / 2);;
  }

  &__multiple-line {

    & textarea {
      max-width: 100%;
      width: 100%;
      height: $grid-gutter-width * 5;
      border-radius: 4px;
      border: 1px solid $color-gray-300;
      padding: 0 10px;

      &:focus {
        color: $color-text-body;
        outline: none;
      }
    }
  }

  &__select-dropdown {
    @extend .career-form-submit__select-dropdown;

    & .input-group {
      border-radius: unset;
    }

    .input-group:after {
      content: '\ea1c';
      font-family: 'icomoon' !important;
      right: 11px;
      top: 8px;
      position: absolute;
      pointer-events: none;
      z-index: $z-index-select;
      transform: rotate(90deg);
    }
  }

  &__full-name,
  &__check-number {
    @extend .career-form-submit__full-name, .career-form-submit__check-number;

    & input {
      border-radius: 4px;
      padding-left: 80px;
    }
  }

  &__full-name {
    .dropdown select {
      padding-left: calc($grid-gutter-width / 2);
    }
  }

  &__full-name,
  &__single-line,
  &__multiple-line,
  &__email,
  &__confirm-email,
  &__number,
  &__telephone,
  &__date-time,
  &__select-dropdown {
    margin-right: 50%;
  }

  &__checked,
  &__check-box,
  &__check-label {
    padding-bottom: calc($grid-gutter-width / 2);;

    & label {
      cursor: pointer;
      font-size: 16px;
      margin-left: 15px;
    }

    input[type="checkbox"] {
      margin-top: 5px;

      &::before {
        width: 22px;
        height: 22px;
        background: $color-white;
        border: 2px solid $color-border-box-price-plans;
      }
    }

    input[type="checkbox"]:checked {
      &::after {
        height: 22px;
        width: 22px;
        font-size: 14px;
        color: $color-white;
        background: $color-primary;
        padding-left: 5px;
      }
    }
  }

  &__checked {
    margin-top: calc($grid-gutter-width / 2);;
  }

  &__radio-button {
    & input[type="radio"] {
      margin-top: 6px;

      &::before {
        width: 22px;
        height: 22px;
        background: $color-white;
        border-radius: 100%;
        border: 2px solid $color-border-box-price-plans;
      }
    }

    & input[type="radio"]:checked {
      &::after {
        width: 12px;
        height: 12px;
        background: $color-border-box-price-plans;
        border-radius: 100%;
      }
    }
  }

  &__date-time {
    position: relative;

    & span {
      position: absolute;
      top: 40px;
      right: 25px;
      font-size: 20px;
    }
  }

  &__submit {
    margin-top: 50px;
  }

  &__upload {
    margin-top: $grid-gutter-width;
  }

  &__upload-title {
    margin-bottom: calc($grid-gutter-width / 2);;
  }

  &__upload-button {
    & label {
      cursor: pointer;
      margin-top: calc($grid-gutter-width / 2);;
    }

    input[type="file"] {
      position: absolute;
      opacity: 0;
    }

    & p {
      color: $color-errors;
      margin-top: 5px;
    }
  }

  &__item {
    margin: 5px 0;
  }

  &__delete {
    text-decoration: underline;
    color: $color-primary;
    cursor: pointer;
    margin-left: 10px;
  }

  &__check-number {
    @extend .career-form-submit__check-number;

    & input {
      border-radius: 4px;
    }
  }

  &__group {
    margin-bottom: $grid-gutter-width;
  }

  @media #{$media-screen-mobile} {
    &__full-name,
    &__single-line,
    &__multiple-line,
    &__email,
    &__confirm-email,
    &__number,
    &__telephone,
    &__date-time,
    &__select-dropdown {
      margin-right: 0;
    }

    &__item-flex {
      display: flex;
      flex-direction: row;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      justify-content: flex-start;
    }
  }

  .select {
    border-radius: unset;
  }

  .styledSelect {
    position: absolute;
    top: 8px;
    right: 2px;
    bottom: 2px;
    left: 0;
    background-color: $color-white;
    padding:0 10px;
    font-weight: 600;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: $color-black transparent transparent transparent;
      position: absolute;
      display: none;
    }
  }

  .styledSelect:active, .styledSelect.active {
    background-color: $color-white;

    &:after {
      transform: unset;
      top: unset;
    }
  }

  .options {
    margin: 0;
    border:1px solid $color-background-linkedin;
    border-radius: unset;
  }

  .options li {
    padding:0 10px;
    margin: 0;
  }
}
