.article-category {
  &__title {
    color: $color-primary;
    font-size: 39px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
  }

  &__left {
    .article-category__button{
      margin-right: 16px;
    }
  }

  &__left, &__right {
    display: flex;
    align-items: center;
  }

  &__type {
    font-size: 18px;
    font-weight: 700;
    margin-right: 16px;
  }

  &__button {
    position: relative;
    cursor: pointer;
  }

  &__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    background-color: $color-tertiary;
    color: $color-white;
    padding: 8px 24px;
    border-radius: 50px;
    min-width: 200px;

    span {
      margin-right: 24px;
    }

    img {
      filter: brightness(10);
      transition: transform .3s;
    }

    &.open {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__items {
    display: none;
    position: absolute;
    z-index: 7;
    width: 100%;
    background-color: $color-tertiary;
    margin: 8px 0;
    padding: 16px;
    border-radius: 32px;

    li {
      padding: 12px;
      font-size: 16px;
      font-weight: 600;
      color: $color-white;

      &.active, &:hover {
        color: $color-primary;
      }
    }
  }

  @media #{$media-not-desktop} {
    &__filter, &__left, &__right {
      flex-direction: column;
      align-items: normal;
    }

    &__left {
      .article-category__selected {
        margin-right: 0;
      }
    }

    &__button {
      margin-right: 0 !important;
      margin-bottom: 12px;
    }

    &__selected {
      margin-right: 0;
    }
  }
}

.article-listing {
  .article-card {
    &__wrapper {
      margin-bottom: 24px;
    }

    &__item {
      box-shadow: 0px 0px 0px 0px rgba(12, 90, 80, 0.10), 0px 16px 34px 0px rgba(12, 90, 80, 0.10), 0px 63px 63px 0px rgba(12, 90, 80, 0.09), 0px 141px 84px 0px rgba(12, 90, 80, 0.05), 0px 250px 100px 0px rgba(12, 90, 80, 0.01), 0px 391px 109px 0px rgba(12, 90, 80, 0.00);
      margin: 0 15px;
      width: auto;

      .img-fluid {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    &__content {
      padding: 32px;
      background-color: $color-white;
    }

    &__desc {
      &--top {
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 32px;
        }
      }

      &--bottom {
        p {
          font-size: 14px;
          margin-top: 32px;
          margin-bottom: 8px;
        }
      }
    }

    @media #{$media-md} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &__wrapper {
        flex-direction: column;
        flex: 0 0 percentage(calc(6/12));
        max-width: percentage(calc(6/12));
        margin-bottom: 32px;
      }
    }

    @media #{$media-lg} {
      &__wrapper {
        flex: 0 0 percentage(calc(4/12));
        max-width: percentage(calc(4/12));
      }
    }
  }

  .article-pagination {
    text-align: center;

    &__text {

    }

    &__pages {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 38px;
    }

    &__prev, &__next {
      cursor: pointer;

      img {
        display: block;
      }
    }

    &__number {
      a {
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        margin: 0 20px;

        &.active {
          background-color: $color-secondary;
          border-radius: 50px;
          padding: 12px 20px;
          color: $color-white;
          margin: 0;
        }

        &:first-child {
          margin-left: 24px;
        }

        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}

