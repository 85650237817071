.a-nav-search {
  position: relative;

  &.no-image {
    position: relative;
    z-index: 4;

    &__green {
      background-color: $color-primary;

      .a-nav-search__sub-link {
        position: relative;
      }
    }

    &__tertiary {
      background-color: $color-tertiary;

      .a-nav-search__sub-link {
        position: relative;
      }
    }

    &__white {
      background-color: $color-white;

      .a-nav-search__sub-link {
        position: relative;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;

    ul {
      display: none;
    }
  }

  &__back {
    display: none;
  }

  &__menu-info {
    .a-nav-search__item.active a {
      color: $color-tertiary;
    }
  }

  &__menu-ic {
    display: none;
  }

  &__wrap {
    background: $color-primary;
    z-index: 9;
    position: relative;
  }

  &__logo {
    display: flex;
    padding-right: calc($grid-gutter-width / 2);

    & img {
      width: 140px;
    }
  }

  &__action {
    display: flex;

    .a-nav-search__icon {
      display: inherit;
      align-self: center;
    }
  }

  &__login {
    display: inline-block;
    padding: 10px 30px;
    clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
    background-color: $color-secondary;

    a {
      text-decoration: none;
      font-size: $font-base;
      color: $color-white;
    }

    img {
      vertical-align: middle;
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &__takecharge {
    display: flex;
    border-radius: 24px;
    align-items: center;
    padding: 4px;
    text-decoration: none;

    &.light {
      background-color: rgba(255, 255, 255, .24);
      transition: background 0.3s;

      .a-nav-search__takecharge-icon {
        background-color: $color-white;
        transform: translateX(0);
        transition: transform 0.3s ease;

        .toggle-off {
          display: none;
        }
      }

      .a-nav-search__takecharge-text {
        color: $color-white;
        padding: 0 10px;
        transform: translateX(0);
        transition: transform 0.3s ease;
      }

      &:hover, &.active {
        .a-nav-search__takecharge-text {
          color: $color-white;
          transform: translateX(-30%);
        }

        .a-nav-search__takecharge-icon {
          background-color: $color-primary;
          transform: translateX(330%);

          .toggle-off {
            display: block;
          }

          .toggle-on {
            display: none;
          }
        }

        background: linear-gradient(to right, #003949, #107D6F);
      }
    }

    &-icon {
      border-radius: 50%;
      width: 40px;
      height: 40px;

      img {
        width: 22px;
        height: 22px;
        margin: 9px;
      }
    }

    @media #{$media-screen-desktop} {
      margin-right: 20px;
    }
  }

  &__toggle-switch {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__icon {
    font-size: $font-base;
    color: $color-white;
    margin-right: 32px;
    cursor: pointer;

    img {
      align-self: center;
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &__login-link {
    display: block;
  }

  &__brand {
    padding: 10px 0;

    img {
      display: block;
    }
  }

  &__sub-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1140px;
    align-content: center;
    padding: 24.5px 0;
    position: absolute;
    z-index: 3;

    .a-nav-search__active-bg--mobile {
      display: none;
    }

    @media #{$media-screen-desktop} {
      max-width: 98%;
    }
  }

  &__sub-link-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  & .dropdown-item:hover {
    background-color: transparent;
  }

  .a-navbar-custom .nav-link {
    color: $color-white;
    font-weight: 700;
    font-size: 18px;
    white-space: normal;
    text-decoration: none;
  }

  .a-navbar-custom .nav-list {
    font-size: 16px;
    font-weight: 400;
    left: 30px;
    position: relative;
    text-decoration: none;
  }

  &__sub-list-links {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    width: 100%;
    justify-content: center;

    &.dark {
      & a {
        text-decoration: none;
        color: $color-sub-item-menu;

        &:hover {
          color: $color-secondary;
        }
      }
    }

    &.light {
      & a {
        text-decoration: none;
        color: $color-white;

        &:hover {
          color: $color-secondary;
        }
      }
    }
  }

  &__link-text {
    &:hover{
      color: $color-tertiary;
    }
  }

  &__sub-link-item {
    padding: 0 20px;
    font-size: map-get($font-size, "size-7");

    @media #{$media-screen-desktop} {
      padding: 0 11px;
    }
  }

  & .navbar {
    padding: 0;
  }

  & .active {
    a {
      color: $color-white;

      &:hover{
        color: $color-tertiary;
      }
    }
  }

  & .sub-active {
    color: $color-secondary !important;
    @extend .font-semi-bold;
  }

  &__item {
    flex: 0 0 auto;
    display: flex;
    font-size: map-get($font-size, "size-7");
    flex-direction: column;
    justify-content: center;
    position: relative;

    + .a-nav-search__item {
      margin-left: 1.5rem;
    }

    & a {
      color: $color-white;
      text-decoration: none;

      &:hover{
        color: $color-tertiary;
      }
    }

    & .is-show {
      display: block;
    }

    .a-nav-search__active-bg {
      display: none;
      position: absolute;
      width: 195px;
      height: auto;
      left: -40px;
      z-index: 2;

      @media #{$media-xl} {
        width: 210px;
      }
    }

    &.active {
      .a-nav-search__active-bg {
        display: none;

        @media #{$media-lg} {
          display: block;
        }
      }
    }
  }

  &__icon {
    display: inline-block;

    & img {
      float: right;
      clear: both;
      cursor: pointer;
    }
  }

  & form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-white;
    margin-bottom: 15px;
  }

  &__form {
    width: 100%;

    & button {
      background: transparent;
      border: none;
      padding-left: 0;
      padding-right: 15px;

      & .icon-search {
        color: $color-white;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    & .icon-close {
      color: $color-white;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    input[type="text"] {
      border: none;
      background: transparent;
      flex: 1 0 auto;
      color: $color-white;
      font-size: $font-base;

      &::placeholder {
        color: $color-white;
        font-size: $font-base;
      }

      &:focus {
        border: none;
        background-color: transparent;
        outline: none;
      }
    }
  }

  &__login-wrapper {
    background-color: $color-white;
    padding: 15px;
    border: 1px solid $color-spacing-text;
    border-top: none;

    &:hover {
      background-color: $color-border-box-price-plans;
      color: $color-white;
      padding: 15px;
      cursor: pointer;

      & .nav-search__login-wrapper-title {
        color: $color-white;
        text-decoration: none;
      }

      & .nav-search__login-wrapper-sub-title {
        color: $color-white;
      }
    }
  }

  &__login-popovers {
    width: 230px;

    a {
      text-decoration: none;
    }

    & .active {
      background-color: $color-border-box-price-plans;
      color: $color-white;
      padding: 15px;
      cursor: pointer;

      & .nav-search__login-wrapper-title {
        color: $color-white;
        text-decoration: none;
      }

      & .nav-search__login-wrapper-sub-title {
        color: $color-white;
      }
    }
  }

  &__login-board-info {
    max-width: 230px;
    font-size: 16px;

    p {
      padding: 0;
    }
  }

  &__login-wrapper-title {
    margin-bottom: 15px;
    color: $color-tertiary;
    text-decoration: underline;

    @extend .font-semi-bold;
  }

  &__login-wrapper-sub-title {
    margin-bottom: 0;
    color: $color-gray-800;
  }

  &__box-toggle {
    display: flex;
    z-index: 2;
    justify-content: center;
  }

  & .navbar-light {
    .navbar-toggler {
      margin-left: 16px;
      width: 28px;
      height: auto;
    }

    .navbar-toggler .closed-icon {
      display: none;
    }

    .navbar-toggler[aria-expanded="true"] .closed-icon {
      display: block;
    }

    .navbar-toggler[aria-expanded="true"] .open-icon {
      display: none;
    }

    .navbar-toggler-icon {
      width: 40px;
      height: 23px;
      background-image: none;
      position: relative;
      border-bottom: 5px solid $color-tertiary;
      transition: all 100ms linear;
    }
  }

  .container-fluid {
    position: relative;
  }

  .overlay {
    display: none;
    background: linear-gradient(180deg, #B7B7B7 0%, rgba(183, 183, 183, 0.00) 100%);
    overflow: hidden;
    position: absolute;
    height: 180px;
    width: 100%;
    top: 44px;
    left: 0;
    z-index: 1;

    @media #{$media-lg} {
      display: block;
    }
  }

  @media #{$media-lg} {
    & .navbar-expand-lg .navbar-collapse {
      justify-content: space-between;
      overflow: unset !important;
    }
  }

  @media #{$media-xl} {
    &__item, &__sub-link-item {
      font-size: $font-base;
    }

    &__logo {
      img {
        width: 140px;
      }
    }
  }

  @media #{$media-not-desktop} {
    & .active {
      a {
        &:hover{
          color: $color-white;
        }
      }
    }

    &__main-menu {
      margin-top: 80px;
    }

    &__menu-info {
      width: 100%;
      height: auto;

      .a-nav-search__item.active a {
        color: $color-white;
      }
    }

    &__action {
      position: absolute;
      top: 48px;
      left: 16px;
    }

    &__box-toggle {
      margin-left: 12px;
      position: relative;
      justify-content: normal;
    }

    &__not-icon-list {
      padding-left: 16px;
    }

    &__form {
      padding-left: 15px;
      padding-right: 15px;

      & input[type="text"] {
        color: $color-input-text-mobile;

        &::placeholder {
          color: $color-white;
          font-size: $font-base;
        }
      }

      & button {
        padding-right: 10px;
      }
    }

    &__logo {
      padding-right: 0;

      & img {
        @include fluid-width(115px, 175px, 768px, 992px);
      }
    }

    &__takecharge {
      width: fit-content;
      margin: 40px auto;
    }

    &__link-text {
      display: inline-block;
      width: calc(100% - 30px);

      &:hover, &:focus, &:active{
        color: $color-primary !important;
      }
    }

    &__not-icon-list {
      &:hover, &:focus, &:active, &:visited {
        color: $color-white !important;
      }
    }

    &__login {
      & a {
        color: $color-tertiary;
        display: inline-block;
        padding: 3px 15px;
        border-radius: 5px;
      }
    }

    &__icon {
      display: inline-block;

      & img {
        float: right;
        clear: both;
        cursor: pointer;
      }
    }

    &__sub-link-item {
      @include fluid-type(strip-unit(map-get($font-size, "size-8")) * $font-base,
        strip-unit(map-get($font-size, "size-6")) * $font-base,
        768px,
        992px);
    }

    &__login-wrapper {
      border-bottom: 1px solid $color-spacing-text;
    }

    &__icon {
      flex-basis: 5%;
      margin-left: 16px;
      color: $color-white;
      z-index: 8;
    }

    &__login {
      padding: 16px;
      clip-path: polygon(30% 0, 100% 0, 70% 100%, 0 100%);

      & a {
        display: inline-block;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    &__mobile-top {
      @include column-gutter();
      display: flex;
      margin: auto;
      width: 100%;
      align-items: center;
      background-color: $color-primary;
      z-index: 2;
    }

    &__form {
      margin: auto;
      width: 100%;
      background-color: $color-primary;
      z-index: 2;
    }

    &__brand {
      flex: 1 0 auto;

      img {
        max-width: 120px;
      }
    }

    &__item {
      justify-content: normal;
      position: unset !important;

      & a {
        color: $color-white;
        text-decoration: none;
        font-size: 26px;

        &:hover{
          color: $color-primary !important;
        }
      }

      + .a-nav-search__item {
        margin: 0;
      }
    }

    &__wrap {
      background: $color-white;
    }

    &__item {
      width: 100%;
      padding-top: 20px;
      position: relative;

      + .nav-search__item {
        margin-left: 0;
      }
    }

    &__menu-ic {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 55px;
      height: 55px;
      padding: 10px;
    }

    &__menu-ic--back {
      position: absolute;
      left: -4px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &__back {
      position: relative;
      margin-bottom: 20px;
      display: block;

      span {
        margin-left: 32px;
        font-weight: bold;
        font-size: 16px;
        color: $color-white;
      }
    }

    &__dropdown {
      .dropdown-item {
        padding-right: 0;
        padding-left: 0;
        position: relative;
      }

      & a {
        color: $color-text-body;
      }
    }

    &__icon-list {
      padding-left: 25px;
    }


    &__list {
      @include column-gutter;
      width: 100%;
      padding-bottom: 0;
      padding-top: 0;
      flex-direction: column;

      ul {
        display: block;
      }
    }

    &__collapse, &__sub-link--wrapper {
      background: rgba(0, 0, 0, 0.88);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(4px);
      height: 0;
      max-height: calc(100vh - 62px);
      position: absolute;
      top: 62px;
      right: 0;
      left: 0;
      bottom: 0;
      overflow-x: scroll;
      z-index: 8;
      transition: height .1s ease-out;

      &.show, &.active {
        height: 100vh;
      }
    }

    &__sub-link-item {
      & a {
        font-size: $font-base;
      }
    }

    &__sub-link--wrapper {
      background: rgba(22, 176, 156, 0.88);
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      z-index: 9;
      overflow-x: scroll;
      display: none;
      width: 100%;

      &.active {
        display: block;
      }

      .a-nav-search__active-bg--mobile {
        display: none;
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &__sub-link-lists {
      padding: 24px;
    }

    &__mobile-top-bar {
      height: 15px;
      width: 100%;
      background: $color-tertiary;
    }

    & .a-navigation-wrap {
      background: $color-white;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
    }

    &__login-board-info {
      max-width: 100%;
    }

    &__login-popovers {
      width: 100%;
    }

    &__login-wrapper {
      background-color: $color-gray-100;
    }

    & .container-fluid {
      padding: 0;

      & .a-navigation-wrap {
        background: $color-white;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;

        &.is-fixed {
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          width: 100%;
          -webkit-transform: translateY(-110%);
          transform: translateY(-110%);
        }

        &.sticky {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }

    .sub-active {
      color: $color-tertiary !important;

      .a-nav-search__active-bg--mobile {
        display: block;
      }
    }
  }

  .navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;
    padding-left: 0;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
