#accordion-slide {
  height: 100vh;
  overflow:hidden;

  @media #{$media-not-desktop} {
    height: auto;
    overflow: visible;
  }
}

.accordion-slide {
  height: 100vh;
  width: 10000px;

  &__image {
    float:left;
    position:relative;
    transition:all 0.6s;
    cursor:pointer;

    &:first-child {
      .accordion-slide__content {
        left: 13%;
      }

      .accordion-slide__slider-item {
        z-index: 5;
      }
    }

    &:nth-child(2) {
      .accordion-slide__slider-item {
        z-index: 4;
      }
    }

    &:nth-child(3) {
      .accordion-slide__slider-item {
        z-index: 3;
      }
    }

    &:nth-child(4) {
      .accordion-slide__slider-item {
        z-index: 2;
      }
    }

    &.image-last {
      .accordion-slide__slider-item {
        width: unset;
        z-index: 1;
      }
    }
  }

  &__slider-item {
    position:relative;
    height: 100vh;
    width: 100%;
    overflow:hidden;
    -webkit-transform: translate3d(0, 0, 0) skew(-20deg, 0deg);
    -moz-transform: translate3d(0, 0, 0) skew(-20deg, 0deg);
    -ms-transform: translate3d(0, 0, 0) skew(-20deg, 0deg);
    transform: translate3d(0, 0, 0) skew(-20deg, 0deg);
    transition:all 0.5s;
    box-shadow: rgba(0, 0, 0, 1) 0 5px 45px;

    &:first-child {
      margin-left:-170px;
    }
  }

  &__slider-image {
    height: 90vh;
    width: 130%;
    top: 0;
    position: absolute;
    left: -130px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
    -moz-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
    -ms-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
    transform: translate3d(0, 0, 0) skew(20deg, 0deg);
    transition: all 0.5s;

    &.overlay {
      width: 400%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
      }
    }
  }

  &__image--mobile {
    display: none;
  }

  &__content {
    display: flex;
    align-items: center;
    left:0;
    opacity:1;
    transition:all 0.5s;
    background-color: $color-primary;
    position: absolute;
    bottom: 0;
    padding: 40px 60px;
    width: 100%;
    min-width: 100%;
    height: auto;

    h4 {
      font-size: 25px;
      color: $color-white;
    }

    p {
      color: $color-white;
    }

    &-inner {
      -webkit-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
      -moz-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
      -ms-transform: translate3d(0, 0, 0) skew(20deg, 0deg);
      transform: translate3d(0, 0, 0) skew(20deg, 0deg);
    }

    &.overlay{
      background-color: $color-tertiary;
      width: max-content;
      min-width: 400%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        z-index: 1;
      }

      &.accordion-slide__content{
        min-width: 200%;
      }
    }
  }

  @media (max-height: 720px){
    &__image {
      &:first-child {
        .accordion-slide__content {
          left: 10%;
        }
      }
    }
  }

  @media #{$media-lg} {
     &__image {
       &:first-child {
         .accordion-slide__content-inner {
           position: relative;
           left: 5%;
         }
       }
    }

    &__content {
      &-inner {
        width: 60%;
        margin: 0 auto 0 80px;
      }
    }
  }

  @media #{$media-large-desktop} {
    &__image {
      &:nth-child(2) {
        .accordion-slide__content-inner {
          left: 0;
        }
      }
    }

    &__content {
      &-inner {
        width: 80%;
      }
    }
  }

  @media #{$media-not-desktop} {
    width: 100%;
    height: 100%;

    &__image {
      float: none;
      width: 100% !important;
      pointer-events: none;

      &:first-child {
        .accordion-slide__content {
          left: 0;
        }
      }

      &:nth-child(2) {
        .accordion-slide__content-inner {
          left: 0;
        }
      }
    }

    &__slider-item {
      margin-left: 0 !important;
      height: auto;
      transform: none;
      box-shadow: none;
    }

    &__slider-image {
      position: relative;
      width: 100%;
      height: auto;
      background-image: unset !important;
      left: 0;
      filter: none !important;
      transform: none;

      &.item-img-1 {
        opacity: 1 !important;
      }

      &.item-img-2 {
        display: none;
      }
    }

    &__image--mobile {
      display: block;
      width: 100%;
      height: auto;
    }

    &__content {
      position: relative;
      width: fit-content;
      background-color: $color-tertiary !important;
      padding: 24px;
      left: 0;
      filter: none !important;
    }

    &__content-inner {
      transform: none;
      margin: 0;
      width: 100%;
    }
  }
}
