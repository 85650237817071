.search-results {

  &__search {
    background: $color-primary;
    padding: 25px 0 15px 0;
    position: relative;
  }

  &__title {
    padding-bottom: 20px;
    color: $color-white;
    font-size: 30px;
  }

  &__description {
    color: $color-white;
    font-size: 16px;
  }
}
