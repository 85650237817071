$price-plan-card-padding-horizontal: calc($grid-gutter-width / 2);;

.promotions-card {
  display: flex;
  height: 100%;
  min-height: 1px;
  justify-content: center;

  &--align-item {
    align-items: center;
    justify-content: center;

    & .price-plan-card__border {
      margin: auto;
    }

    @media #{$media-screen-mobile-small} {
      align-items: stretch;
    }
  }

  &__border {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    box-shadow: 0 0 0 0 rgba(12, 90, 80, 0.10), 0 16px 34px 0 rgba(12, 90, 80, 0.10), 0 63px 63px 0 rgba(12, 90, 80, 0.09), 0 141px 84px 0 rgba(12, 90, 80, 0.05), 0 250px 100px 0 rgba(12, 90, 80, 0.01), 0 391px 109px 0 rgba(12, 90, 80, 0.00);
    background-color: white;
    margin-left: calc($grid-gutter-width / 2);
    margin-right: calc($grid-gutter-width / 2);
    overflow: hidden;
    max-width: 350px;

    &.min-height-570 {
      min-height: 570px;
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__top-line {
    text-align: center;
    margin-top: 25px; //15px;
    margin-bottom: 15px;
    padding-left: $price-plan-card-padding-horizontal;
    padding-right: $price-plan-card-padding-horizontal;
  }

  &__promo-type {
    margin-bottom: 8px;
  }

  &__promo-code {
    margin-bottom: 24px;

    &--label {
      font-size: map-get($font-size, "size-7");
      margin-bottom: 8px;
    }

    &--wrapper {
      display: flex;
      align-items: baseline;
      margin-bottom: 6px;
      cursor: pointer;
      position: relative;

      &.disabled {
        .promotions-card__promo-code--code {
          color: #cccccc;
          pointer-events: none;
        }

        &:hover {
          .promotions-card__promo-code--code {
            text-decoration: none;
            color: #cccccc;
          }
        }
      }

      .promotions-card__tooltip {
        display: none;
        position: absolute;
        background-color: #547A85;
        font-size: 12px;
        color: $color-white;
        border-radius: 16px;
        padding: 8px;
        right: 0;
        top: 36px;
        transition: opacity 1s;
        z-index: 2;
        margin: 0 !important;

        &.show {
          opacity: 1;
        }
        &.hide {
          opacity: 0;
        }
      }

      &:hover {
        .promotions-card__promo-code--code {
          color: $color-secondary;
          text-decoration: underline;
        }

        .promotions-card__tooltip {
          display: block;
        }
      }

      @media (max-width: #{$break-point-1366}) {
        &:hover, &:active, &:focus {
          .promotions-card__promo-code--code {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }
    }

    &--code {
      font-size: 17px;
      font-weight: 700;
      color: $color-primary;
      margin-right: 5px;
    }

    &--desc {
      font-size: map-get($font-size, "size-7");
      margin-right: 8px;
    }

    &--icon {
      align-self: normal;
    }
  }

  &__title {
    @extend .text-ellipsis;
    color: $color-primary;
    cursor: pointer;
    margin-bottom: 16px;

    &.dark-title {
      color: $color-tertiary;
    }
  }

  &__subtitle {
    font-size: 16px;
    overflow: hidden;
    margin: 0;
    color: $color-card-subtitle;
    font-weight: 600;
  }

  &__content {
    &-text {
      font-size: map-get($font-size, "size-7");
      margin-bottom: 24px;
    }

    &-valid {
      font-size: map-get($font-size, "size-7");
      font-style: italic;
      margin-bottom: 14px;
    }
  }

  &__business-title {
    @extend .text-ellipsis;

    font-size: 22px;
    margin: 0;
    text-align: center;
    -webkit-line-clamp: 2;
  }

  &__price {
    margin-bottom: 16px;
    text-align: left;
  }

  &__tnc {
    & a {
      color: $color-primary !important;
      text-decoration: none;
    }
  }

  &__contact, &__tnc {
    margin: 0;
    font-weight: 700;
    font-size: 14px;

    & a {
      color: $color-tertiary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-gst {
      font-size: 14px;
      color: $color-tertiary;
      padding-top: 12px;
      font-weight: 400;
    }
  }

  &__price-desc {
    color: $color-primary;
    margin-bottom: 15px;
    font-weight: 700;
  }

  &__middle {
    flex: 0 0 auto;
    position: relative;

    .featured-plan__card--item {
      margin: 0;
    }

    .featured-plan__card--content {
      min-height: unset;
      padding: 32px;

      .featured-plan__card--top {
        margin: 0;
      }

      .featured-plan__card--desc-right {
        width: 55%;
      }
    }
  }

  &__image {
    display: block;
    margin: 0;

    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__box-static {
    max-width: max-content !important;
  }

  &__box-static--relative {
    position: relative;

    .price-plan-card__promotion-valid {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    img {
      position: absolute;
    }
  }

  &__bot-line {
    padding: $grid-gutter-width;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__list-text {
    font-size: 14px;
    padding-top: 15px;

    .rich-text ul {
      padding-left: 20px;
    }
  }

  &__item-logo {
    &--img {
      position: absolute;
      width: 54px;
      height: auto;
      bottom: 12px;
      right: 12px;
      z-index: 3;
    }

    .triangle {
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      border-style: solid;
    }

    .big {
      border-width: 185px 105px 0 0;
      border-color: transparent $color-secondary transparent transparent;
    }

    .small {
      border-width: 175px 100px 0 0;
      border-color: transparent $color-white transparent transparent;
      z-index: 2;
    }
  }

  &__box-dynamic {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__price-title {
    margin-bottom: 5px;
    color: $color-primary;
  }

  &__disabled {
    position: absolute;
    height: 100%;
    width: 100%;

    .promotions-card__overlay {
      background-color: $color-black;
      opacity: 0.6;
      mix-blend-mode: multiply;
      height: 100%;
      width: 100%;
      z-index: 8;
      position: relative;
    }

    .promotions-card__triangle {
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      border-style: solid;
      border-width: 185px 105px 0 0;
      border-color: $color-secondary transparent transparent transparent;
      z-index: 9;
    }

    .promotions-card__triangle--text {
      color: $color-white;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      width: 150px;
      position: absolute;
      top: 45px;
      left: -40px;
      transform: rotate(-60deg);
      z-index: 9;
    }
  }

  & .title-custom-font {
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  @media #{$media-screen-tablet} {
    &__business-title {
      height: 50px;
      font-size: $font-size-base;
    }

    &__border {
      width: 320px;
    }
  }

  @media #{$media-md} {
    flex: 1 0 auto;

    &__title {
      @include fluid-type-not-mobile(18px, 20px);
    }

    &__price-title {
      @include fluid-type-not-mobile(18px, 36px);

      &.title-custom-font {
        @include fluid-type-not-mobile(16px, 22px);
      }
    }

    &__price-desc {
      @include fluid-type-not-mobile(14px, 16px);
    }

    &__promotion-valid {
      font-size: map-get($font-size, "size-8");
    }
  }

  @media #{$media-screen-mobile} {
    &.custom-open-card {
      .promotions-card__border {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__title {
      @include fluid-type-mobile(18px, 20px);
    }

    &__price-title {
      @include fluid-type-mobile(18px, 26px);

      &.title-custom-font {
        @include fluid-type-mobile(16px, 22px);
      }
    }

    &__discount-title {
      @include fluid-type-mobile(24px, 32px);
    }

    &__price-desc {
      @include fluid-type-mobile(14px, 16px);
    }

    &__promotion-valid {
      font-size: map-get($font-size, "size-8");
    }

    &__border {
      margin-left: calc($grid-gutter-width / 2);
      margin-right: 0;
    }

    &__disabled {
      .promotions-card__triangle--text {
        font-size: 18px;
        line-height: 18px;
        top: 42px;
        left: -38px;
      }
    }
  }

  @media #{$media-screen-mobile-normal} {
    &__bot-line {
      padding: 20px $price-plan-card-padding-horizontal;
    }
  }
}

.slide-in-component {
  position: relative;
  right: 0;
  top: 0;
  transform: translate3d(15%, 0, 0);
  opacity: 0.2;

  &.slide-in {
    animation: slideInComponent 1.5s forwards;
  }
}

.slide-in-card {
  transform: translate3d(15%, 0, 0);
  transition: transform 0.5s ease;

  &.slide-in {
    animation: slideIn 1.5s forwards;
  }
}

@keyframes slideInComponent {
  0% {
    transform: translate3d(15%, 0, 0);
    opacity: 0.2;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translate3d(15%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
