.summary-electricity-plan {
  &__title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  &__check-referral {
    position: relative;
  }

  &__check-referral-title {
    display: flex;
    font-size: 20px;
    margin-bottom: 10px;
    color: $color-primary;
  }

  &__check-referral-desc {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__check-referral-info {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      .summary-electricity-plan__check-referral-info-desc {
        display: block;
      }
    }
  }

  &__check-referral-info-desc {
    display: none;
    border-radius: 4px;
    background: rgba(0, 57, 73, 0.64);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    color: $color-white;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    padding: 16px;
    width: 60%;
    top: 35px;
    left: 135px;
    position: absolute;
    z-index: 2;

    p {
      margin: 0;
    }
  }

  &__icon {
    vertical-align: middle;
  }

  &__price-title {
    font-size: 16px;
    color: $color-primary;
  }

  &__price-text {
    font-size: 48px;
    margin-bottom: 32px;
  }

  @media #{$media-screen-mobile} {
    &__check-referral-title {
      font-size: 18px;
    }

    &__check-referral-desc {
      font-size: 16px;
    }

    &__price-title {
      font-size: 18px;
    }

    &__price-text {
      font-size: 40px;
    }
  }
}
