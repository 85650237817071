.featured-plan {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin-bottom: 5rem;
  position: relative;

  &__mobile-bg {
    width: 100%;
    height: auto;
  }

  &__tooltip {
    display: flex;
    align-items: center;

    &--img {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
    }

    &--icon {
      position: relative;
      margin-left: 4px;

      &:hover {
        cursor: pointer;
        position: relative;

        .featured-plan__tooltip--img {
          background-image: url('../assets/images/info-ic.svg') !important;
        }

        .featured-plan__tooltip--text {
          display: block;
        }
      }
    }

    &--text {
      font-size: 12px;
      border-radius: 2px;
      background: rgba(0, 57, 73, 0.64);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      padding: 6px 8px;
      position: absolute;
      display: none;
      width: max-content;
      z-index: 1;
    }

    img {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &__bg {
    @include make-container-fluid();
    position: relative;
    bottom: 0;

    @media #{$media-md} {
      justify-content: flex-end;
      bottom: unset;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
    }

    &--content {
      @include column-gutter();
      @extend .mb-component;
      height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: normal;

      &.rich-text {
        h3 {
          font-size: 31px;
        }
      }

      @media #{$media-md} {
        flex: 0 0 percentage(calc(6/12));
        justify-content: center;
        margin-bottom: 0;
        padding-top: 80px;
        padding-bottom: 64px;

        p {
          font-size: 20px;
          font-weight: 400;
        }
      }

      @media #{$media-lg} {
        flex: 0 0 percentage(calc(5/12));
      }
    }
  }

  &__card {
    &.single-card {
      .featured-plan__card--item {
        margin: 0;
      }
    }

    &--title {
      margin-bottom: 50px;

      h4 {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &--bestrate {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      &-text {
        font-size: 14px;
        font-weight: 700;
        color: $color-secondary;
        margin-left: 4px;
      }

      img {
        display: block;
      }
    }

    &--item {
      margin: 0 12px;
      cursor: pointer;
    }

    &--content {
      position: relative;
      min-height: 340px;

      &.primary--bg {
        background-color: $color-primary;
      }

      &.secondary--bg {
        background-color: $color-secondary;
      }

      &.tertiary--bg {
        background-color: $color-tertiary;
      }

      padding: 20px 32px;

      &.single {
        min-height: unset;
      }
    }

    &--desc {
      img {
        display: block;
        width: auto;
        height: 38px;
      }
    }

    &--logo {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    &--top {
      margin-bottom: 48px;
    }

    &--head span,
    &--head h3,
    &--price span,
    &--price p, {
      color: $color-white;
      font-weight: 700;
    }

    &--head span {
      margin-bottom: 8px;
    }

    &--head h3 {
      font-size: 36px;
      margin-bottom: 14px;
    }

    &--price p {
      font-size: 34px;
      margin-bottom: 0;

      @media #{$media-screen-mobile} {
        font-size: 28px;
      }
    }

    &--gst {
      color: $color-white;
      font-size: 14px;
    }

    &--btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &--triangle {
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
      transition: all .3s ease-out;
      z-index: 2;
      opacity: 1;

      &-green {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      &.js-open-slide-custom {
        opacity: 0;
      }
    }

    &--icon {
      position: absolute;
      right: 16px;
      bottom: 30px;
      cursor: pointer;
      transition: all .3s ease;
      opacity: 1;
      z-index: 2;

      &.js-open-slide-custom {
        opacity: 0;
      }
    }

    &--icon-hover {
      position: absolute;
      right: 16px;
      bottom: 15px;
      cursor: pointer;
      transition: all .3s ease-out;
      opacity: 0;
      z-index: 2;
    }

    @media (min-width: $break-point-1024 + 1) {
      &:hover {
        .featured-plan__card--triangle {
          transform: scale(2.3);
          right: 60px;
          bottom: 105px;
        }

        .featured-plan__card--icon {
          opacity: 0;
        }

        .featured-plan__card--icon-hover {
          bottom: 15px;
          opacity: 1;
        }
      }
    }

    @media #{$media-md} {
      min-width: 600px;

      &.single-card {
        min-width: unset;
      }

      &--wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  @media #{$media-md} {
    height: 820px;
    margin-bottom: 10rem;
  }

  @media #{$media-screen-mobile} {
    background-image: none !important;
  }
}
