.notification {
  background: $color-link-text;
  position: relative;
  z-index: 9999;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  & .is-hidden {
    display: none;
  }

  &__main {
    display: flex;
    align-items: center;
    // flex-grow: 1;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0;

    &--style {
      background: $color-bg-maitenance;
    }

    &.primary{
      background: $color-primary;
    }
  }

  &__title {
    color: $color-white;
    font-size: 14px;
    max-width: 750px;
    padding-right: 40px;

    @media #{$media-screen-mobile} {
      padding: 0;
    }
  }

  & .icon-close, & .icon-maintenance,
  & .icon-bell,  & .icon-info-bubble {
    color: $color-white;
    font-size: 30px;
    padding-right: 40px;
    margin-top: -5px;
  }

  & .icon-close {
    font-size: 25px;
    padding-right: 0;
    padding-left: 40px;
    margin: 0;
  }

  &.fixed{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99999;

    & .icon-maintenance,
    & .icon-bell,  & .icon-info-bubble {
      font-size: 80px;
    }

    @media #{$media-screen-mobile} {
      text-align: center;

      .notification__wrapper{
        display: block;
      }

      .icon-close, .icon-maintenance,
      .icon-bell, .icon-info-bubble {
        display: none;
      }

      .btn.btn-secondary{
        margin: 0;
        margin-top: 10px;
        margin-bottom: 35px;
      }
    }
  }

  a{
    color: $color-white;
    font-weight: bold;
  }

  h6{
    color: $color-white;
  }

  @media #{$media-lg} {
    transform: translateZ(0);
  }
}
