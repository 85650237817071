.fullscreen-parallax {
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100vh;

    @media (max-height: 720px) and (min-width: 991px) {
      height: unset;
    }

    @media #{$media-not-desktop} {
      height: unset;
    }
  }

  &__container {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-height: 720px) and (min-width: 991px) {
      position: relative;
    }

    @media #{$media-not-desktop} {
      position: relative;
    }
  }

  &__background {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 20%;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    will-change: transform;
    z-index: -1;

    &-mobile {
      display: none;
    }

    &.left {
      background-position: 80%;
    }
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media #{$media-xl} {
      position: absolute;
      width: 100%;
      top: 20%; bottom: 0;
      left: 0; right: 0;
    }
  }

  &__info {
    @include column-gutter();
    z-index: 1;
    position: relative;
    flex: 60% 0 0;
    padding-top: $component-bottom;
    padding-bottom: $component-vertical-padding;

    .hero-image__title {
      color: $color-tertiary;
      font-size: 56px;
      font-weight: 700;
      line-height: 67px;
    }

    .hero-image__desc {
      color: $color-tertiary;
      font-size: map-get($font-size, "size-5");
      font-weight: 600;
      line-height: 26px;
      padding: 50px 20% 0;
    }
  }

  &__img {
    margin-bottom: 32px;
    text-align: center;

    img {
      width: 80%;
      height: auto;
    }
  }

  &__content {
    position: absolute;
    bottom: 40px;
    right: 80px;
    font-family: $font-montserrat;
    width: calc(#{percentage(calc(3/12))});

    &.left {
      left: 80px;
      right: 0;
    }

    &-wrapper {
      position: relative;
      overflow: hidden;
    }

    &-inner {
      background: rgba(22, 176, 156, 0.88);
      backdrop-filter: blur(12px);
      padding: 40px;
    }

    &-info {
      z-index: 3;
      position: relative;

      &.single {
        padding-top: 32px;
      }

      &.rich-text {
        ul li {
          color: $color-white;
        }
      }
    }

    &-title {
      cursor: pointer;
      color: $color-white;
      margin-top: 0 !important;
      margin-bottom: 16px !important;
    }

    &-subtitle {
      margin-top: 0 !important;
      margin-bottom: 8px !important;
    }

    &-desc {
      color: $color-white;
      font-size: map-get($font-size, "size-5");
    }

    &-link {
      font-size: map-get($font-size, 'size-5');
      font-weight: 700;
      line-height: 24px;
      text-decoration: none;
      padding: 15px 24px;
    }

    @media (min-width: #{$break-point-992}) {
      width: calc(#{percentage(calc(5/12))});
    }

    @media (max-height: 800px) {
      width: calc(#{percentage(calc(6/12))});
    }

    @media #{$media-screen-tablet} {
      right: 40px;

      &.left {
        left: 40px;
      }
    }

    @media (min-width: 991px) and (max-height: 720px)  {
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      position: relative;

      &.left {
        left: 0;
        right: 0;
      }

      &-desc,  &-link {
        font-size: map-get($font-size, "size-6");
      }

      &-inner {
        height: 100%;
      }

      &-info {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &-image-mobile {
        height: 100%;

        & img {
          width: 100%;
          height: 100%;
          display: block;
          transform: none;
        }
      }
    }

    @media #{$media-not-desktop} {
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      position: relative;

      &.left {
        left: 0;
        right: 0;
      }

      &-desc,  &-link {
        font-size: map-get($font-size, "size-6");
      }

      &-inner {
        height: 100%;
      }

      &-info {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &-image-mobile {
        height: 100%;

        & img {
          width: 100%;
          height: 100%;
          display: block;
          transform: none;
        }
      }
    }
  }

  @media (max-height: 720px) and (min-width: 991px) {
    &__background {
      display: none;

      &-mobile {
        display: block;

        .img-fluid {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  @media #{$media-not-desktop} {
    &__background {
      display: none;

      &-mobile {
        display: block;

        .img-fluid {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  .rich-text {
    h1, h2, h3, h4, h5, h6, ul, li, ol, p, span {
      color: $color-white;
    }
  }
}
