.section-electricity {
  background: $color-tertiary;
  padding: 40px 0 50px 0;
  position: relative;

  &__title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  &__desc {
    font-size: 20px;
    font-weight: 500;
  }
}
