.search-suggestion {
  .dropdown-item:hover {
    color: inherit;
    background-color: inherit;
  }

  .selector {
    position: relative;

    &__input {
      padding-left: 45px;
      outline: none;
      box-shadow: none !important;
      font-size: 16px;
      height: 50px;
      border-color: $color-spacing-text;
      font-family: $font-montserrat;
    }

    input.form-control:focus {
      border-color: $color-border-input-search;
    }

    input.form-control:focus + ul {
      border-top-color: $color-border-input-search;
    }

    &--wrapper {
      overflow: hidden;
    }

    & .icon-search {
      width: 25px;
      height: 25px;
      position: absolute;
      left: 10px;
      top: 12px;
      background-size: 100%;
      transition: all 0.3s ease;
      font-size: 30px;
      color: $color-spacing-text;
    }

    &__item {
      white-space: normal;
      padding: 0 0 0 0;

      &:nth-child(2) {
        padding-top: 10px;
      }

      &:last-of-type {
        padding-bottom: 10px;
      }

      &--hide {
        display: none;
      }
    }

    &-menu {
      width: 100%;
      max-height: 180px;
      overflow: auto;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      margin-top: -1px;
      border: 1px solid $color-spacing-text;
    }

    &-item {
      padding: 8px 15px !important;
      cursor: pointer;
      width: 100%;
      display: block;

      &:hover,
      &:focus,
      &.active {
        text-decoration: none;
        background-color: #f8f9fa;
      }
    }
  }

  &__search-filter {
    cursor: pointer;
    position: absolute;
    background-size: 100%;
    transition: all 0.3s ease;
    font-size: 30px;
    color: $color-spacing-text;
    right: 16px;
    z-index: 1;
    top: 11px;
    padding-left: 10px;
    border-left: 1px solid $gray-600;

    &:before {
      color: $gray-600;
    }
  }

  &--custom {
    .selector {
      &__input {
        padding-left: calc($grid-gutter-width / 2);;
        padding-right: $grid-gutter-width  * 2;
      }
    }

    width: 50%;
    min-width: 300px;
    padding-left: 0;
    position: relative;
    margin: auto;

    & span {
      display: none;
    }

    &__input {
      padding-left: calc($grid-gutter-width / 2);;
    }

    @media #{$media-screen-mobile-normal} {
      max-width: 260px;
    }
  }
}

