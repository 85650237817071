.confirm-dialog {
  & .modal-header {
    margin: 20px 0 0 0;
    padding: 0;
    border-bottom: none;
  }

  & .modal-close {
    right: -25px;
    top: -8px;

    & .icon-close:before {
      font-size: 15px;
    }
  }

  & .modal-body__content {
    text-align: center;
    & button {
      min-width: 8rem;
    }
  }


  & .modal-title {
    @include fluid-type-mobile(16px, 22px);

    color: $color-tertiary;
    margin-top: calc($grid-gutter-width / 2);;
    font-size: $font-base;
  }
}
