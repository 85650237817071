.discover {
  position: relative;
  bottom: -130px;
  margin-top: -80px;

  &__wrap {
    margin: 40px 0;
  }

  &__section-title {
    text-align: center;
    color: $color-primary;
    font-size: 39px;
  }

  &__card {
    box-shadow: 0px 0px 0px 0px rgba(12, 90, 80, 0.10), 0px 16px 34px 0px rgba(12, 90, 80, 0.10), 0px 63px 63px 0px rgba(12, 90, 80, 0.09), 0px 141px 84px 0px rgba(12, 90, 80, 0.05), 0px 250px 100px 0px rgba(12, 90, 80, 0.01), 0px 391px 109px 0px rgba(12, 90, 80, 0.00);
    position: relative;
    margin-bottom: 32px;

    .link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
    }

    &:hover {
      .discover__content-icon {
        right: 20px;
        bottom: 28px;
      }

      .discover__content-bg {
        transform: scale(1.1);
        right: 4px;
        bottom: 7px;
      }
    }

    &.single-card {
      display: flex;
      background-color: #EAFFFC;

      .discover__image {
        order: 2;
        flex: 0 0 46%;
        height: 100%;

        img {
          object-fit: cover;
        }
      }

      .discover__content {
        background-color: #EAFFFC;
        flex: 0 0 54%;

        &-desc {
          margin: 5% 0;
        }

        &-head, &-text {
          color: $color-tertiary;
        }
      }

      @media #{$media-not-desktop} {
        display: block;

        .discover__image {
          min-height: unset;
        }

        .discover__content {
          width: 100%;

          &-desc {
            margin: 0;
          }
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 200px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 32px 50px 32px 32px;
    background: $color-white;
    position: relative;
    min-height: 170px;

    &-head {
      color: $color-primary;
      margin-bottom: 16px;
    }

    &-text {
      padding-right: 40px;
      margin-bottom: 0;
    }

    &-bg {
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
      transition: all 0.3s ease-out;
    }

    &-icon {
      position: absolute;
      right: 16px;
      bottom: 24px;
      cursor: pointer;
      transition: all 0.3s ease-out;
    }
  }

  @media #{$media-screen-mobile} {
    &__content {
      &-head {
        font-size: 21px;
      }
    }
  }
}
