$price-plan-card-padding-horizontal: calc($grid-gutter-width / 2);;

.price-plan-card {
  display: flex;
  height: 100%;
  min-height: 1px;

  &--align-item {
    align-items: center;
    justify-content: center;

    & .price-plan-card__border {
      margin: auto;
    }

    @media #{$media-screen-mobile-small} {
      align-items: stretch;
    }
  }

  &__border {
    display: flex;
    flex-direction: column;
    height: auto;
    border: $thick-border-width solid $color-border-box-price-plans;
    border-radius: 30px;
    background-color: white;
    margin-left: calc($grid-gutter-width / 2);;
    margin-right: calc($grid-gutter-width / 2);;
    overflow: hidden;
    max-width: 350px;
  }

  &__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__top-line {
    text-align: center;
    margin-top: 25px; //15px;
    margin-bottom: 15px;
    padding-left: $price-plan-card-padding-horizontal;
    padding-right: $price-plan-card-padding-horizontal;
  }

  &__title {
    @extend .text-ellipsis;
  }

  &__subtitle {
    font-size: 15px;
    overflow: hidden;
    margin: 0;
    color: $color-card-subtitle;
    font-weight: 600;
  }

  &__business-title {
    @extend .text-ellipsis;

    font-size: 22px;
    margin: 0;
    text-align: center;
    -webkit-line-clamp: 2;
  }

  &__price {
    text-align: center;
  }

  &__quantity {
    text-align: center;
  }

  &__promotion-valid,
  &__price-desc, &__quantity-desc {
    color: $color-gray-800;
    margin-bottom: 15px;
  }

  &__middle {
    flex: 0 0 auto;
  }

  &__image {
    display: block;
    margin: 0;

    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__box-static {
    margin-top: 10px;
  }

  &__box-static--relative {
    position: relative;

    .price-plan-card__promotion-valid {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    img {
      position: absolute;
    }
  }

  &__bot-line {
    padding: calc($grid-gutter-width / 2) $price-plan-card-padding-horizontal;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__discount {
    text-align: center;
  }

  &__discount-title {
    color: $color-primary;
  }

  &--price-item {
    text-align: center;
  }

  &__discount-text {
    color: $color-gray-800;
    font-weight: 400;
  }

  &__list-text {
    font-size: 14px;
    padding-top: 15px;
    border-top: 1px solid $color-text-underline;

    .rich-text ul {
      padding-left: 20px;
    }
  }

  &__box-dynamic {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__cta {
    margin-bottom: 20px;

    & button {
      display: block;
    }
  }

  &__price-title, &__quantity-title {
    margin-bottom: 5px;
  }

  &__cta-download {
    // display: flex;
    // flex-direction: row;

    * {
      display: block;
      text-align: center;
    }

    //@media #{$media-xl} {
    //  * {
    //    display: inline;
    //  }

      //* + * {
      //  &:before {
      //    content: "|";
      //    padding-right: 5px;
      //  }
      //}
    //}
  }

  // &__modal {
  //   flex: 0 0 auto;
  //   margin-right: 3px;
  // }

  &__modal,
  &__pdf {
    color: $color-tertiary;
    text-decoration: underline;
    cursor: pointer;
  }

  & .title-custom-font {
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  @media #{$media-screen-tablet} {
    &__business-title {
      height: 50px;
      font-size: $font-size-base;
    }
  }

  @media #{$media-md} {
    flex: 1 0 auto;

    &__title {
      @include fluid-type-not-mobile(23px, 30px);
    }

    &__price-title {
      @include fluid-type-not-mobile(18px, 26px);

      &.title-custom-font {
        @include fluid-type-not-mobile(16px, 22px);
      }
    }

    &__discount-title {
      @include fluid-type-not-mobile(24px, 32px);
    }

    &__modal,
    &__pdf,
    &__price-desc,
    &__paragraph {
      @include fluid-type-not-mobile(14px, 16px);
    }

    &__promotion-valid {
      font-size: map-get($font-size, "size-8");
    }
  }

  @media #{$media-screen-mobile} {
    &__title {
      @include fluid-type-mobile(20px, 30px);
    }

    &__price-title {
      @include fluid-type-mobile(18px, 26px);

      &.title-custom-font {
        @include fluid-type-mobile(16px, 22px);
      }
    }

    &__discount-title {
      @include fluid-type-mobile(24px, 32px);
    }

    &__modal,
    &__pdf,
    &__price-desc,
    &__discount-text,
    &__paragraph {
      @include fluid-type-mobile(14px, 16px);
    }

    &__promotion-valid {
      font-size: map-get($font-size, "size-8");
    }
  }

  @media #{$media-screen-mobile-normal} {
    &__bot-line {
      padding: 20px $price-plan-card-padding-horizontal;
    }
  }
}
