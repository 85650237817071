.image-inline-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
  text-align: center;

  &__item {
    flex-grow: 1;
    flex-basis: percentage(calc(6/12));
    margin-bottom: 1rem;
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);

    @media #{$media-sm} {
      flex-basis: percentage(calc(4/12));
    }

    @media #{$media-md} {
      flex-basis: percentage(calc(3/12));
    }

    @media #{$media-lg} {
      flex-basis: percentage(calc(2/12));
    }
  }
}
