.leader-board {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: $thick-border-width solid $color-border-box-price-plans;
    border-radius: 30px;
    background-color: white;
    margin-top: calc($grid-gutter-width / 2);;
    overflow: hidden;
  }

  &__person {
    display: flex;
    margin: 0 15px;
    padding: 20px 0;
    border-bottom: 1px solid $color-border-form-input;

    &:last-child {
      border-bottom: none;
    }
  }

  &__image {
    margin-left: 5px;
    margin-right: 15px;
    flex: 0 0 60px;
    height: 60px;
    width: 60px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 3px solid $color-border-image;
  }

  &__title {
    width: 60%;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
  }

  &__name {
    @extend .font-bold;

    color: $color-tertiary;
    display: flex;

    & > span {
      padding-right: 5px;
    }
  }

  &__point {
    @extend .font-semi-bold;

    color:$color-point;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 calc(100% - 60px);
    width: calc(100% - 60px);
  }

  @media #{$media-screen-tablet} {
    &__title {
      width: 80%;
    }
  }

  @media #{$media-screen-mobile} {
    &__title {
      @include fluid-type-mobile(16px, 26px);
      width: 90%;
      margin-bottom: 15px;
    }
  }
}
