.price-plan-calculator {
  @media #{$media-md} {
    .container-fluid {
      max-width: 1200px;
    }
  }

  @media #{$media-screen-mobile} {
    .price-plans__with-calculator {
      .price-plans__item {
        width: 360px;
      }
    }
  }
}

.price-plans {
  // &__heading {
  //   margin: 20px 0 10px;
  //   text-align: center;
  // }

  &.no-dots {
    .slick-dots {
      display: none !important;
    }
  }

  &__item {
    outline: none;

    & > .component {
      height: 100%;
    }

    &.card-blur {
      filter: blur(4px);
      pointer-events: none;
    }
  }

  // &__text {
  //   font-size: 16px;
  //   color: $color-gray-800;
  //   max-width: 41%;
  //   margin: 0 auto 25px;
  // }

  &__cta {
    text-align: center;

    & a {
     font-weight: 700;

     &:focus {
       outline: none;
     }
    }
  }

  & .slick-dotted.slick-slider {
    margin-bottom: 60px;
  }

  &.price-plans__with-calculator {
    .price-plans__item {
      flex: unset !important;
      max-width: unset !important;
    }

    .promotions-card {
      @media #{$media-screen-mobile} {
        flex-direction: column;
      }
    }
  }

  @media #{$media-md} {
    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      margin-bottom: 30px;
      flex: 0 0 33.3333%;
    }
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(calc(4/12));
      max-width: percentage(calc(4/12));
    }

    & .col-desktop-3 {
      & .price-plans__item {
        flex: 0 0 percentage(calc(4/12));
        max-width: percentage(calc(4/12));
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .price-plans__item {
        flex: 0 0 percentage(calc(6/12));
        max-width: percentage(calc(6/12));
      }
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(calc(6/12));
      max-width: percentage(calc(6/12));
    }

    & .col-tablet-3 {
      & .price-plans__item {
        flex: 0 0 percentage(calc(4/12));
        max-width: percentage(calc(4/12));
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .price-plans__item {
        flex: unset;
        max-width: percentage(calc(6/12));
      }
    }
  }

  @media #{$media-screen-mobile} {
    overflow-x: clip;

    .container-fluid & {
      margin-left: - calc($grid-gutter-width / 2);
      margin-right: - calc($grid-gutter-width / 2);
    }

    & .slick-initialized {
      & .slick-track {
        display: flex;
      }

      & .slick-slide {
        height: auto;
        align-self: baseline;
      }

      & .slick-list {
          overflow: visible;
      }
    }

    &__cta {
      & a {
        font-size: 16px;
      }
    }

    &__item {
      width: 330px;
    }
  }

  @media #{$media-screen-mobile-normal} {
    &__cta {
      & a {
        margin: 0 0 10px 20px;
        padding: 10px 30px;
      }
    }
  }
}
