.rate-card {
  &__container {
    max-width: 1440px;
  }

  .rate-card__item--bottom {
    margin-top: 0;
  }

  &__item {
    background-color: $color-white;
    box-shadow: 0px 0px 0px 0px rgba(22, 114, 96, 0.10), 0px 22px 48px 0px rgba(22, 114, 96, 0.10), 0px 87px 87px 0px rgba(22, 114, 96, 0.09), 0px 196px 117px 0px rgba(22, 114, 96, 0.05), 0px 348px 139px 0px rgba(22, 114, 96, 0.01), 0px 543px 152px 0px rgba(22, 114, 96, 0.00);
    margin-bottom: 16px;

    &--secondary {
      .rate-card__heading,
      .rate-card__rate,
      .rate-card__text {
        color: $color-secondary;
      }

      .rate-card__rate span {
        color: $color-tertiary;
      }
    }
  }

  &__heading {
    text-align: center;
    padding: 14px;
    background-color: #D0EFEB;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);

    p {
      font-size: 21px;
      font-weight: 700;
      margin: 0;
    }
  }

  &__rate {
    text-align: center;
    padding: 20px;

    p {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__text {
    p {
      padding: 0 32px 32px;
      text-align: center;
      font-size: 21px;
      font-weight: 700;
    }
  }

  &__tnc {
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    p {
      margin-top: 24px;
    }
  }

  @media #{$media-md} {
    &__two {
      .rate-card__item--bottom {
        margin-top: 32px;
      }
    }

    &__three {
      .rate-card__item--bottom {
        margin-top: 50px;
      }
    }

    &__heading {
      padding: 12px;

      p {
        @include fluid-type-not-mobile(21px, 25px);
      }
    }

    &__rate {
      p {
        @include fluid-type-not-mobile(40px, 48px);
      }
    }

    &__text {
      p {
        @include fluid-type-not-mobile(21px, 25px);
      }
    }
  }
}
