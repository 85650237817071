*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
}

body {
  margin: 0; // 1
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; // 3
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

h1 {
  font-size: map-get($font-size, "size-1");
}

h2 {
  font-size: map-get($font-size, "size-2");
}

h3 {
  font-size: map-get($font-size, "size-3");
}

h4 {
  font-size: map-get($font-size, "size-4");
}

h5 {
  font-size: map-get($font-size, "size-5");
}

h6 {
  font-size: map-get($font-size, "size-6");
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: none;
  }
}

input[type="submit"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button,
input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
    border: 1px solid $color-gray-300;
    border-radius: 4px;

    &:focus {
      color: $color-text-body;
      outline: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

input[type="checkbox"]:checked {
  &::after {
    position: absolute;
    content: '✔';
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

input[type="radio"]:checked {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  @include font-size(inherit);
  line-height: inherit;
}

button {
  &:focus {
    outline: none;
  }
}

b,
strong {
  font-weight: bold;
}

input {
  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

a {
  color: $link-color;
  // text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  &:focus {
    outline: none;
  }
}

@keyframes scale {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

h1, .h1 {
  @include fluid-type-not-mobile(32px, 48px);
  line-height: 1.2;
  color: $color-tertiary;
}

h2, .h2 {
  @include fluid-type-not-mobile(30px, 38px);
  line-height: 1.2;
  color: $color-tertiary;
}

h3, .h3 {
  @include fluid-type-not-mobile(26px, 32px);
  line-height: 1.2;
  color: $color-tertiary;
}

h4, .h4 {
  @include fluid-type-not-mobile(22px, 24px);
  line-height: 1.3;
  color: $color-tertiary;
}

h5, .h5 {
  @include fluid-type-not-mobile(20px, 20px);
  line-height: 1.3;
  color: $color-tertiary;
}

h6, .h6 {
  @include fluid-type-not-mobile(18px, 18px);
  line-height: 1.3;
  color: $color-tertiary;
}

/* for content */
p { margin-bottom: 1rem; }
