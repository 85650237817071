.content-slider {
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;

  &__overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;

    &--img {
      width: 100%;
      height: auto;
    }
  }

  &.right-image {
    .first-container {
      left: -5%;
      right: 0;
    }

    .second-container {
      right: -30%;
      left: unset;
    }

    .third-container {
      padding: 0 0 0 100px;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      left: 55px;
    }

    .slick-initialized {
      .slick-dots {
        right: 0;
        text-align: right;
      }
    }

    &.single-image {
      .first-container {
        padding-right: 0;
        padding-left: 50px;
      }
    }
  }

  .first-container {
    position: relative;
    right: -5%;
    z-index: 1;
    transform: scale(.75);
  }

  .second-container {
    position: absolute;
    left: -30%;
    filter: blur(4px);
    top: 0;
  }

  .third-container {
    margin: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 100px 0 0;

    .content-slider__item {
      margin-bottom: 64px;
      align-self: flex-end;

      &:not(.slick-active) {
        pointer-events: none;
      }

      &.rich-text {
        .content-slider__item-title {
          margin-bottom: 8px;
          span {
            color: $color-tertiary;
          }
        }

        .content-slider__item-desc {
          color: $color-tertiary;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 16px;
        }

        .content-slider__link {
          color: $color-secondary;
        }
      }
    }

    .content-single {
      .content-slider__item {
        pointer-events: unset;
      }
    }
  }

  &.single-image {
    .first-container {
      position: relative;
      left: 0;
      z-index: 1;
      transform: scale(1);
      padding-right: 50px;
      padding-left: 0;
    }

    .third-container {
      margin: auto 0;
    }
  }

  &__item {
    position: relative;

    &.iframe {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-top: 56.25%;
    }

    &-title {
      color: $color-primary;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 24px;
    }

    &-desc {
      color: $color-tertiary;
      font-size: 18px;
      line-height: 27px;
    }

    &-img {
      width: 100%;
      height: auto;
    }

    &-logo {
      width: 125px;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;

      &--img {
        position: absolute;
        width: 125px;
        height: auto;
        bottom: 12px;
        right: 12px;
        z-index: 3;
      }

      .triangle {
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        border-style: solid;
      }

      .big {
        border-width: 414px 218px 0 0;
        border-color: transparent $color-secondary transparent transparent;
      }

      .small {
        border-width: 400px 210px 0 0;
        border-color: transparent $color-white transparent transparent;
        z-index: 2;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &__textHead {
    margin: 0;
  }

  &__title {
    color: $color-primary;
    margin-bottom: 16px;
  }

  &__subTitle {
    color: $color-primary;
    margin-bottom: 8px;
  }

  &__link {
    font-size: map-get($font-size, 'size-5');
    font-weight: 700;
    line-height: 24px;
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-list.draggable {
    width: 100%;
  }

  .slick-initialized {
    .slick-dots li button {
      width: 8px;
      height: 8px;
    }

    .slick-dots {
      bottom: 0;
      margin-bottom: 14px;
      text-align: left;

      li {
        width: 8px;
        height: 8px;
        margin: 0 14px;

        &:first-child {
          margin-left: 0;
        }

        button:before {
          width: 8px;
          height: 8px;
          background-color: #D9D9D9;
          border: none;
        }

        &:hover {
          button:before {
            background-color: #EB6028;
          }
        }

        &.slick-active button:before {
          background-color: $color-primary;
          width: 35px;
          border-radius: 50px;
          transform: translateX(-35%);
          transition: 0.3s;
        }

        &.slick-active:first-child {
          button:before {
            width: 20px;
            transform: translateX(-10%);
          }
        }

        &.slick-active:last-child {
          button:before {
            width: 20px;
            transform: translateX(-10%);
          }
        }
      }
    }
  }

  .slick-prev, .slick-next {
    left: unset;
    top: unset;
    bottom: 0;
    transform: none;
    width: 40px;
    z-index: 3;
    height: 40px;
    border-radius: 0;

    &:before {
      @extend %icon;
      position: absolute;
      bottom: 0;
      transform: translate(-50%);
      color: $color-tertiary;
      opacity: 1;
    }
  }

  .slick-prev {
    right: 55px;

    &::before {
      content: url('../assets/images/slider/left.svg');
    }

    &:hover {
      &::before {
        content: url('../assets/images/slider/left-orange.svg');
      }
    }
  }

  .slick-next {
    right: 0;

    &::before {
      content: url('../assets/images/slider/right.svg');
    }

    &:hover {
      &::before {
        content: url('../assets/images/slider/right-orange.svg');
      }
    }
  }

  @media #{$media-not-desktop} {
    &__overlay {
      bottom: 20%;
    }

    &__item {
      margin-bottom: 16px;
    }

    &.right-image {
      .first-container {
        left: 0;
      }

      .second-container {
        right: -65%;
      }

      .third-container {
        padding: 0 15px;
      }

      &.single-image {
        .first-container {
          padding-left: 0;
        }
      }
    }

    &.single-image {
      .first-container {
        padding-right: 0;

        .content-single {
          transform: translateY(0) !important;
        }
      }

      .third-container {
        margin: 0;
      }
    }

    .first-container {
      right: 0;
      transform: scale(.85);
    }

    .second-container {
      left: -65%;
    }

    .third-container {
      padding: 0 15px;
    }

    & .slick-track {
      background: transparent;
    }

    & .slick-prev, .slick-next {
      top: unset;
      bottom: 0;

      &:before {
        top: unset;
        bottom: 0;
      }
    }

    & .slick-slide {
      height: auto;
      display: block;
    }
  }

  @media #{$media-screen-mobile} {
    &__item {
      &-logo {
        &--img {
          width: 70px;
          height: auto;
        }

        .big {
          border-width: 219px 127px 0 0;
        }

        .small {
          border-width: 209px 121px 0 0;
        }
      }
    }
  }
}
