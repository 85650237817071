.subnavigation {
  background: $color-show-menu-background;

  &-automore {
    display: inline-block;
  }

  & .subnavigation-automore {
    position: relative;
  }

  &__menu-list {
    background: $color-show-menu-background;
    display: inline-block;
  }

  & .subnavigation-item,
  & .subnavigation-link,
  & .dropdown-item {
    color: $color-nav-link;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 8px 19px 8px;
    margin-right: 60px;
    cursor: pointer;
  }

  & .active {
    border-bottom: 4px solid #eb6028;
    color: $color-tertiary;
    display: inline-block;
  }

  & .subnavigation-link {
    padding: 0;
    position: relative;
  }

  & .icon-arrow-up,
  & .icon-arrow-down {
    cursor: pointer;
    right: -28px;
    position: absolute;
    z-index: 1;
    top: 2px;
  }

  &--mobile-item {
    display: block;
    padding-bottom: 15px;
  }

  & .dropdown-item {
    white-space: normal;

    &.active, &:active, &:hover {
      background-color: transparent;
    }
  }

  & .dropdown-menu {
    border: none;
    margin-top: 30px;
    padding: 0;
    text-align: center;
    background: $color-show-menu-background;
    top: calc(100% - 15px) !important;
    left: -50% !important;
    border-radius: 0;
    transform: none !important;
  }

  & .dropdown {
    display: inline-block;
  }


  @media #{$media-screen-desktop} {
    & .dropdown {
      text-align: center;
      display: block;
    }

    & .dropdown-menu {
      top: calc(100% + 15px) !important;
      margin: 0;
      padding: 0;
      transform: none !important;
      background: transparent;
      width: 100%;

      & .active {
        display: none;
        color: $color-tertiary;
      }
    }
  }

  @media #{$media-screen-tablet} {
    & .dropdown-menu {
      top: calc(100% - 15px) !important;
      left: -65% !important;
    }
  }

  @media #{$media-md} {
    & .subnavigation-item {
      display: inline-block;
      margin-right: 60px;
    }
  }

  @media #{$media-screen-mobile} {
    & .dropdown {
      text-align: center;
      display: block;
    }

    & .dropdown-menu {
      margin: 0;
      padding: 0;
      top: 100% !important;
      left: 0 !important;
      transform: none !important;
      background: transparent;
      width: 100%;

      & .active {
        display: none;
        color: $color-tertiary;
      }
    }

    & .subnavigation-automore {
      position: relative;
      display: block;
      text-align: center;
      padding-bottom: 15px;

      & a {
        position: relative;
        color: $color-nav-link;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        padding: 15px 0 5px 0;
        cursor: pointer;
      }
    }

    & .subnavigation-automore>a>span {
      color: $color-tertiary;
    }

    & .icon-arrow-up,
    & .icon-arrow-down {
      right: -28px;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 20px;
      transition: .4s;
    }

    &--item-link {
      display: inline-block;
    }

    &--mobile-item {
      display: block;
    }
  }
}
