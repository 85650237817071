.rich-text {
  @include rich-text();

  .content--inverse & {
    h1, h2, h3, h4, h5, h6, p, a, span, li {
      color: inherit;
    }
  }
  &--padding-bottom {
    & ul li {
      padding-bottom: calc($grid-gutter-width / 2);;
      color: $color-text-body;
    }
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    &.green-heading {
      color: $color-primary;
    }
  }
}
