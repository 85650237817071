.s-hidden {
  visibility:hidden;

  &:after {
    border: none;
  }
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 80px;

  &.ws-success {
    border: 1px solid $color-primary;

    .options {
      border: 1px solid $color-primary;
    }
  }
}

.styledSelect {
  position: absolute;
  top: 8px;
  right: 2px;
  bottom: 2px;
  left: 0;
  background-color: transparent;
  padding:0 16px;

  &:after {
    content: url('../assets/images/icon-chev-down.svg');
    right: 11px;
    top: -3px;
    position: absolute;
    pointer-events: none;
    z-index: $z-index-select;
  }
}

.styledSelect:active, .styledSelect.active {
  background-color: transparent;

  &:after {
    transform: rotate(180deg);
    top: -13px;
  }
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: -1px;
  left: -1px;
  z-index:999;
  margin-top: 8px;
  padding: 0;
  list-style:none;
  border: 1px solid $color-gray-300;;
  background-color:white;
  -webkit-box-shadow:0 1px 2px $color-box-shadow-table;
  -moz-box-shadow:0 1px 2px $color-box-shadow-table;
  box-shadow:0 1px 2px $color-box-shadow-table;
  border-radius: 20px;
}

.options li {
  padding: 8px 16px;
  margin: 0;
  font-weight: bold;

  &:hover {
    background-color: $color-background-hover-option;
    color: $color-white;
  }
}
