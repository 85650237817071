.faq-accordion {
  &__title {
    color: $color-primary;
    font-size: map-get($font-size, "size-2");
    text-align: center;
  }

  &__expand {
    color: $color-primary;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: map-get($font-size, "size-3");
    }

    &-expand {
      font-size: map-get($font-size, "size-5");
      text-decoration: none;
      cursor: pointer;
      align-self: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__wrapper {
    margin-top: 16px;
  }

  &__list, &__item-year {
    color: $color-tertiary;
  }

  &__list {
    padding-bottom: 5px;
    border-bottom: 1px solid $color-border-text;
  }

  &__item-title {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:last-child {
      .faq-accordion__item-label {
        border: none;
      }

      .faq-accordion__item-text {
        border-bottom: 2px solid rgba(22, 176, 156, 0.16);
      }
    }
  }

  &__item-label {
    border-bottom: solid 2px;
    border-color: $color-white;
    width: 100%;
    padding-right: 32px;
    padding-bottom: 26px;
    color: $color-primary;
    font-size: map-get($font-size, "size-5");

    &.expanded {
      border-color: transparent;
    }
  }

  &__box-item {
    width: 100%;
  }

  &__items {
    position: relative;
    background-color: #E8F7F5;
    padding: 26px 40px 0;
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;

    &:hover {
      background-color: $color-primary;

      .faq-accordion__item-label {
        color: $color-white;
        border-color: transparent;
      }

      .faq-accordion__icon {
        filter: brightness(10);
      }
    }
  }

  &__item-text {
    margin: 0 40px;
    padding: 32px;
    display: none;
    border-right: 2px solid rgba(22, 176, 156, 0.16);
    border-left: 2px solid rgba(22, 176, 156, 0.16);
  }

  &__icon {
    position: absolute;
    right: 40px;
    width: 32px;
    height: auto;
    bottom: 0;
    top: 0;
    margin: auto;
    transform: rotate(0deg);
    transition: transform 0.3s;

    &.rotate-icon {
      transform: rotate(180deg);
    }
  }

  .rich-text {
    font-size: map-get($font-size, "size-6");

    iframe {
      height: 595px;
    }
  }

  & .active {
    display: block;
  }

  @media #{$media-screen-mobile} {
    &__expand {
      &-title {
        font-size: map-get($font-size, "size-4");
      }

      &-expand {
        font-size: map-get($font-size, "size-7");
      }
    }

    &__items {
      padding: 16px 16px 0;
    }

    &__item-text {
      padding: 16px 24px;
      margin: 0 16px;
    }

    &__item-label {
      padding-bottom: 16px;
      font-size: map-get($font-size, "size-6");
    }

    &__icon {
      right: 16px;
    }

    .rich-text {
      font-size: map-get($font-size, "size-7");

      .pr-3 {
        padding-right: 0!important;
      }

      iframe {
        height: 195px;
      }
    }
  }
}
